import { TeliaIcon, TeliaLink } from '@teliads/components/react';

import './DocumentAssetContainer.scss';

interface Props {
  title: string;
  url: string;
}

export default function DocumentAssetContainer({ url, title }: Props) {
  return (
    <span className="document-asset" data-testid="richtext-telia-document">
      <TeliaIcon name="doc" allyTitle="doc icon" />
      <TeliaLink href={url} target="_blank" download={title}>
        {title}
      </TeliaLink>
    </span>
  );
}
