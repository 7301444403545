import { getFixedT } from 'i18next';

import { TFunction, TranslationKeys } from 'i18n';
import { URL_LANGUAGE_ENUM } from 'utils/constants';

enum SLUG_FRAGMENT {
  SV_SLUG = 'svSlug',
  EN_SLUG = 'enSlug',
}

export const getAvailableSlugs = (i18nKey: TranslationKeys) => {
  const translateToEnglish: TFunction = getFixedT(URL_LANGUAGE_ENUM.ENGLISH);
  const translateToSwedish: TFunction = getFixedT(URL_LANGUAGE_ENUM.SWEDISH);

  return getLocalizedSlugs(translateToEnglish(i18nKey), translateToSwedish(i18nKey));
};

export const slugLocaleMap = new Map<URL_LANGUAGE_ENUM, SLUG_FRAGMENT>([
  [URL_LANGUAGE_ENUM.SWEDISH, SLUG_FRAGMENT.SV_SLUG],
  [URL_LANGUAGE_ENUM.ENGLISH, SLUG_FRAGMENT.EN_SLUG],
]);

export const getLocalizedSlugs = (enSlug: string, svSlug?: string) => {
  return {
    [SLUG_FRAGMENT.EN_SLUG]: enSlug,
    [SLUG_FRAGMENT.SV_SLUG]: svSlug || enSlug,
  };
};
