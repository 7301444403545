import { TeliaButton, TeliaIcon } from '@teliads/components/react';
import { ReactNode, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ModalOverlay from 'components/custom/base-modal/ModalOverlay';
import Heading from 'components/voca/heading/Heading';
import { focusFirstInteractiveElement, focusLock } from 'utils/accessibility-focus';
import { animationDurationMs } from 'utils/constants';
import useEventOnEscapeKey from 'utils/hooks/useEventOnEscapeKey';
import { disableScroll, enableScroll } from 'utils/utils';

import 'components/custom/base-modal/BaseModal.scss';

interface Props {
  variant: 'side' | 'center' | 'center-large';
  header: ReactNode;
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  isFullWidth?: boolean;
  isRenderedOnOpen?: true;
  isHeaderCentered?: true;
  focusFirstInteractive?: boolean;
}

export default function BaseModal({
  variant,
  header,
  children,
  onClose,
  isOpen,
  isFullWidth,
  isRenderedOnOpen,
  isHeaderCentered,
  focusFirstInteractive,
}: Props) {
  const modalRef = useRef<HTMLDivElement>(null);
  const [userFocusBeforeModalOpen, setUserFocusBeforeModalOpen] = useState<HTMLElement>();
  const isSideModal = variant === 'side';
  const animationClassName = isSideModal ? 'right-to-left' : 'opacity';
  const ModalHTMLElement = isSideModal ? 'aside' : 'div';

  function returnUserFocus() {
    userFocusBeforeModalOpen && userFocusBeforeModalOpen.focus();
  }

  function onModalOpen() {
    if (document.activeElement instanceof HTMLElement) {
      setUserFocusBeforeModalOpen(document.activeElement);
    }

    disableScroll();
  }

  function onModalClose() {
    returnUserFocus();
    enableScroll();
  }

  useEventOnEscapeKey(onClose);

  return (
    <div className={`${!isOpen ? 'base-modal-wrapper--hidden' : `base-modal-wrapper--${variant}`}`}>
      <CSSTransition
        nodeRef={modalRef}
        in={isOpen}
        timeout={animationDurationMs}
        classNames={animationClassName}
        mountOnEnter={isRenderedOnOpen}
        unmountOnExit={isRenderedOnOpen}
        onEnter={onModalOpen}
        onEntered={focusFirstInteractive ? () => focusFirstInteractiveElement(modalRef) : undefined}
        onExited={onModalClose}
      >
        <ModalHTMLElement
          ref={modalRef}
          className={`base-modal base-modal--${variant} ${
            isFullWidth ? 'base-modal--full-width' : ''
          }`}
          aria-modal="true"
          aria-label={`${variant} modal`}
          role="dialog"
          data-testid="base-modal"
          onKeyDown={(e: React.KeyboardEvent) => focusLock(e, modalRef)}
        >
          <header
            className={`base-modal-header base-modal-header--${variant} ${
              isHeaderCentered ? 'base-modal-header--vertical-center' : ''
            }`}
            data-testid="base-modal-header"
          >
            <Heading
              className={`base-modal-header__title base-modal-header__title--${variant}`}
              tag="h2"
              variant="title-200"
              dataTestId="base-modal-header-title"
            >
              {header}
            </Heading>

            <TeliaButton
              allyLabel="Close modal"
              variant="tertiary-purple"
              size="sm"
              className={`base-modal-header__button base-modal-header__button--${variant}`}
              data-testid="telia-modal-close-button"
              type="button"
              onClick={onClose}
            >
              <TeliaIcon name="close" size="md" />
            </TeliaButton>
          </header>

          <div
            className={`base-modal-content base-modal-content--${variant}`}
            data-testid="base-modal-content"
          >
            {children}
          </div>
        </ModalHTMLElement>
      </CSSTransition>

      <ModalOverlay isOpen={isOpen} onClose={onClose} />
    </div>
  );
}
