import { DocumentNode, useQuery } from '@apollo/client';
import { OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';

import { CONTENTFUL_LANGUAGE_ENUM } from '../constants';

import { contentfulLanguage } from 'apollo';

export default function useI18nQuery<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const currentContentfulLanguage = contentfulLanguage();

  return useQuery<TData, TVariables>(query, {
    ...options,
    variables: {
      ...(options ? options.variables : {}),
      locale: currentContentfulLanguage,
    } as TVariables & { locale: CONTENTFUL_LANGUAGE_ENUM },
  });
}
