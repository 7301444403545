import { TeliaGrid } from '@teliads/components/react';
import { useMemo } from 'react';

import { useHeader } from '../../contexts/Header.context';
import { Link, Variant } from '../../types/common';
import NavLink from '../NavLink/NavLink';
import Section from '../Section/Section';
import NavTeaser from '../Teaser/NavTeaser';

import styles from './MegaMenu.module.scss';
import { Section as SectionType } from './types';

import { mappedTeaserData } from 'components/voca/the-header/transformers';

type Props = {
  open: boolean;
  rootLink: Link;
  sections: SectionType[];
  navTeasers?: mappedTeaserData;
  handleNavigation: () => void;
};

export default function MegaMenu({
  rootLink,
  sections,
  open,
  handleNavigation,
  navTeasers,
}: Props) {
  const { headerHeight } = useHeader();

  const megaMenuStyle = useMemo(
    () => ({
      maxHeight: `calc(100vh - ${headerHeight}px)`,
      top: `${headerHeight}px`,
    }),
    [headerHeight]
  );

  return (
    <nav
      style={megaMenuStyle}
      className={`${styles['mega-menu']}${open ? ` ${styles['mega-menu--open']}` : ''}`}
      data-testid="mega-menu"
    >
      <TeliaGrid className={styles['mega-menu__grid-wrapper']}>
        <div>
          <div className={styles['mega-menu__root-link']}>
            <NavLink
              url={rootLink.url}
              title={rootLink.title}
              variant={Variant.LEVEL_1}
              onClick={handleNavigation}
            />
          </div>
          <div className={styles['mega-menu__row']}>
            {sections.map(({ heading, links, sectionLink }) => (
              <Section
                key={`${sectionLink?.title}-${sectionLink?.url}`}
                heading={heading}
                links={links}
                sectionLink={sectionLink}
                handleNavigation={handleNavigation}
              />
            ))}
          </div>
        </div>
        {navTeasers && navTeasers.length !== 0 && (
          <>
            {navTeasers.map(
              (item) =>
                rootLink.title === item.section && (
                  <NavTeaser
                    key={item.section}
                    teaserHeading={''}
                    image={{
                      url: item.content.image.url,
                      description: item.content.image.altText,
                    }}
                    teaserTitle={item.content.title}
                    cta={{
                      ariaLabel: item.content.title,
                      title: item.content.tagline || '',
                      url: item.content.url,
                    }}
                  />
                )
            )}
          </>
        )}
      </TeliaGrid>
    </nav>
  );
}
