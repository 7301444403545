import { useEffect, useRef, useState } from 'react';

export const useDetectScrollDown = () => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const oldScrollY = useRef<number>(0);

  useEffect(() => {
    const controlDirection = () => {
      const scrollDiff = oldScrollY.current - window.scrollY;

      if (oldScrollY.current > 60 && scrollDiff < 0 && !isScrollingDown) {
        setIsScrollingDown(true);
      }

      if (scrollDiff > 0 && isScrollingDown) {
        setIsScrollingDown(false);
      }

      oldScrollY.current = window.scrollY;
    };

    window.addEventListener('scroll', controlDirection);

    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, [isScrollingDown]);

  return isScrollingDown;
};
