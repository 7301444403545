import { PropsWithChildren, useRef } from 'react';

import './TableRte.scss';

import useHorizontalScrollGradient from 'utils/hooks/useHorizontalScrollGradient/useHorizontalScrollGradient';

export default function TableRte({ children }: PropsWithChildren) {
  const tableWrapperRef = useRef(null);
  useHorizontalScrollGradient(tableWrapperRef);

  return (
    <div ref={tableWrapperRef} className="table-wrapper" data-testid="richtext-table">
      <table className="table">
        <>{children}</>
      </table>
    </div>
  );
}
