import { Paragraph } from '@purpurds/purpur';
import './calendarButton.scss';
import { addMinutes, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import PurpurIcon from 'components/purpur/Icon/Icon';

interface Event {
  start: string;
  end: string;
  title: string;
  description: string;
  url: string;
}

interface AddToCalendarProps {
  eventTitle: string;
  startDateTime: string;
  description: string;
  filename?: string;
  calendarUrl?: string;
}

const formatDateToICS = (dateString: Date | string) => {
  const date = new Date(dateString);
  const utcDate = utcToZonedTime(date, 'UTC');
  return format(utcDate, "yyyyMMdd'T'HHmmss'Z");
};

const generateICS = (event: Event) => {
  const { start, end, title, description, url } = event;
  const uid = crypto.randomUUID();

  return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Teliacompany//v2.0//EN
BEGIN:VEVENT
UID:${uid}
SEQUENCE:0
DTSTAMP:${start}
DTSTART:${start}
DTEND:${end}
SUMMARY:${title}
DESCRIPTION:${`${description} 
  Event url: ${url}`}
URL;VALUE=URI:${url}
X-ALT-DESC;FMTTYPE=text/html: <html><body><p>${`${description}</p>
  <p>Event url: ${url}`}</p></body></html>
END:VEVENT
END:VCALENDAR`;
};

const createFile = (content: string) => {
  const blob = new Blob([content], { type: 'text/calendar' });
  return URL.createObjectURL(blob);
};

const AddToCalendar = ({
  eventTitle,
  startDateTime,
  description,
  filename = `${eventTitle}.ics`,
  calendarUrl,
}: AddToCalendarProps) => {
  const { t, i18n } = useTranslation();
  const defaultUrl = calendarUrl
    ? `https://www.teliacompany.com${calendarUrl}`
    : `https://www.teliacompany.com/${i18n.language}/${t('link_newsroom')}`;

  const start = formatDateToICS(startDateTime);
  const end = formatDateToICS(addMinutes(new Date(startDateTime), 15));

  const event = {
    start,
    end,
    title: `Teliacompany ${eventTitle}`,
    description,
    url: defaultUrl,
    location,
  };

  const icsContent = generateICS(event);
  const fileUrl = createFile(icsContent);

  return (
    <a
      href={fileUrl}
      download={filename}
      target="_blank"
      rel="noopener noreferrer"
      className="calendar__button"
      aria-label={t('add_to_calendar')}
    >
      <Paragraph variant="additional-100">{t('add_to_calendar')}</Paragraph>
      <PurpurIcon iconName="calendar" size="xs" />
    </a>
  );
};

export default AddToCalendar;
