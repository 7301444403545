import { spacing } from '@teliads/components/foundations';

import en from '../../locales/en.json';
import sv from '../../locales/sv.json';

import SearchInput from './SearchInput';
import { SearchInputProps } from './types';

export default function StandaloneSearchInput({
  value,
  updateValue,
  onSearch,
  placeholder,
  language = 'en',
  isWithDropdown = true,
  i18n,
  searchSuggestions,
  isAllResultsLinkHidden = false,
  inputHeight = spacing.spacing48,
  minLength = 1,
  minLengthErrorMessage = '',
  maxLength = 100,
  maxLengthErrorMessage = '',
  pattern = '',
  patternErrorMessage = '',
  isNavigationInput = false,
}: SearchInputProps) {
  i18n.addResourceBundle('en', 'telia-navigation', en);
  i18n.addResourceBundle('sv', 'telia-navigation', sv);

  return (
    <SearchInput
      value={value}
      updateValue={updateValue}
      onSearch={onSearch}
      placeholder={placeholder}
      language={language}
      isWithDropdown={isWithDropdown}
      searchSuggestions={searchSuggestions}
      isAllResultsLinkHidden={isAllResultsLinkHidden}
      inputHeight={inputHeight}
      minLength={minLength}
      minLengthErrorMessage={minLengthErrorMessage}
      maxLength={maxLength}
      maxLengthErrorMessage={maxLengthErrorMessage}
      pattern={pattern}
      patternErrorMessage={patternErrorMessage}
      isNavigationInput={isNavigationInput}
    />
  );
}
