import { makeVar } from '@apollo/client';

import { NavigationClass } from 'components/voca/navigation/navigation-stub/models';
import NavigationStub from 'components/voca/navigation/navigation-stub/NavigationStub';
import { CONTENTFUL_LANGUAGE_ENUM, URL_LANGUAGE_ENUM } from 'utils/constants';

export const urlLanguage = makeVar<URL_LANGUAGE_ENUM>(URL_LANGUAGE_ENUM.ENGLISH);
export const contentfulLanguage = makeVar<CONTENTFUL_LANGUAGE_ENUM>(
  CONTENTFUL_LANGUAGE_ENUM.ENGLISH
);
export const currentNavStub = makeVar<NavigationClass>(
  NavigationStub.get(URL_LANGUAGE_ENUM.ENGLISH)!
);
