import i18n, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import sv from './locales/sv.json';
import { URL_LANGUAGE_ENUM } from './utils/constants';

export type TranslationKeys = keyof typeof en;

export type TFunction = <K extends TranslationKeys>(key: K, options?: TOptions | string) => string;

export function getKeyByValue(translations: StringsDictionary, value: string): string | undefined {
  return Object.keys(translations).find((key) => translations[key] === value);
}

const translations = {
  [URL_LANGUAGE_ENUM.ENGLISH]: {
    translation: en,
  },
  [URL_LANGUAGE_ENUM.SWEDISH]: {
    translation: sv,
  },
};

export const initI18n = (language = URL_LANGUAGE_ENUM.ENGLISH) => {
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: language,
    fallbackLng: URL_LANGUAGE_ENUM.ENGLISH,

    interpolation: {},
  });
};
