import { useQuery } from 'react-query';

import { fetchSearchSuggestions } from 'services/search.service';
import useDebounceValue from 'utils/hooks/useDebounceValue';

export default function useGetSearchSuggestions(value: string, language: string): string[] {
  const debouncedValue = useDebounceValue(value);

  const { data: suggestions, isError } = useQuery(
    [debouncedValue, language],
    () => fetchSearchSuggestions(debouncedValue, language),
    {
      enabled: debouncedValue.length >= 2,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
    }
  );

  return isError ? [] : suggestions;
}
