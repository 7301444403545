import { TeliaImage, TeliaLinkImage } from '@teliads/components/react';

interface Props {
  imgSrc: string;
  alt: string;
  hyperlink: string | undefined;
  description: string;
}

const PHONE_NUMBER_STRING = 'phone number';

export default function ImageAssetContainer({ imgSrc, alt, hyperlink, description }: Props) {
  const containsPhoneNumber = alt.toLowerCase().includes(PHONE_NUMBER_STRING);
  const displayAlt = alt.split(' ').slice(0, 2).join(' ');
  const phoneNumberHref = `tel:${description}`;
  const imageClassName = containsPhoneNumber
    ? 'richtext-element__phone-number'
    : 'richtext-element';
  const imageHyperlink = containsPhoneNumber ? phoneNumberHref : hyperlink;
  return (
    <>
      {hyperlink || containsPhoneNumber ? (
        <TeliaLinkImage
          htmlHref={imageHyperlink}
          linkText={displayAlt}
          className={imageClassName}
          dataTestid="richtext-telia-image"
        >
          <TeliaImage
            src={imgSrc}
            alt={alt}
            variant="scalable"
            className={imageClassName}
            dataTestid="richtext-telia-image"
          />
        </TeliaLinkImage>
      ) : (
        <TeliaImage
          src={imgSrc}
          alt={alt}
          variant="scalable"
          className="richtext-element"
          dataTestid="richtext-telia-image"
        />
      )}
    </>
  );
}
