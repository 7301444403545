import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { NavigationLink } from '../../../voca/navigation/navigation-stub/models';
import ExpansionPanel from '../../expansion-panel/ExpansionPanel';

interface Props {
  navigationLink: NavigationLink;
  parentNavigationLink: NavigationLink;
  onNavigationChange: () => void;
}

export default function MobileHeaderNestedLinks({
  navigationLink,
  parentNavigationLink,
  onNavigationChange,
}: Props) {
  const { t } = useTranslation();

  let navigationLinkContent;

  if (navigationLink.path === '' && navigationLink.name === '') {
    navigationLinkContent = null;
  } else if (navigationLink.children) {
    const childParent = navigationLink.children[0];

    navigationLinkContent = (
      <ExpansionPanel
        title={
          navigationLink.name
            ? navigationLink.name
            : `${t('more_about')} ${parentNavigationLink.name}`
        }
      >
        <ul className="mobile-header-navigation__nested-list">
          {navigationLink.children.map((navigationLink: NavigationLink) => (
            <MobileHeaderNestedLinks
              key={navigationLink.id || childParent.path}
              navigationLink={navigationLink}
              parentNavigationLink={childParent}
              onNavigationChange={onNavigationChange}
            />
          ))}
        </ul>
      </ExpansionPanel>
    );
  } else {
    navigationLinkContent = (
      <Link
        className="mobile-header-navigation__link"
        to={navigationLink.directPath ? navigationLink.directPath : parentNavigationLink.path}
        onClick={onNavigationChange}
      >
        {navigationLink.name}
      </Link>
    );
  }

  return navigationLinkContent ? (
    <li className="mobile-header-navigation__list-item">{navigationLinkContent}</li>
  ) : (
    <></>
  );
}
