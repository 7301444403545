import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { urlLanguage } from 'apollo';

export default function Logo() {
  const { t } = useTranslation();

  const currentUrlLanguage = urlLanguage();
  const home = `/${currentUrlLanguage}`;

  return (
    <Link to={home}>
      <img
        className="telia-company-logo"
        src="/image/Telia_Company_logo.svg"
        alt={t('telia_company_logo')}
        width="170px"
        height="100%"
      />
    </Link>
  );
}
