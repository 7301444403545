import { TeliaIcon } from '@teliads/components/react';
import { close, search } from '@teliads/icons';
import {
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { KEYBOARD_KEY_ARROW_DOWN } from '../../constants';
import InputError from '../InputError/InputError';
import SearchDropdown from '../SearchDropdown/SearchDropdown';

import styles from './SearchInput.module.scss';
import { SearchInputProps } from './types';

const MAX_SUGGESTIONS = 6;

const MIN_DROPDOWN_SHOW_VALUE_LENGTH = 2;

export default function SearchInput({
  value,
  updateValue,
  onSearch,
  placeholder,
  language = 'en',
  isWithDropdown = true,
  searchSuggestions,
  isAllResultsLinkHidden = false,
  handleBlur,
  inputHeight = 'auto',
  minLength = 1,
  minLengthErrorMessage = '',
  maxLength = 100,
  maxLengthErrorMessage = '',
  pattern = '',
  patternErrorMessage = '',
  isNavigationInput = true,
}: Omit<SearchInputProps, 'i18n'>) {
  const { t } = useTranslation();

  const [isPatternError, setIsPatternError] = useState(false);
  const [isMinLengthError, setIsMinLengthError] = useState(false);
  const [isMaxLengthError, setIsMaxLengthError] = useState(false);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [dropdownFocus, setDropdownFocus] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchWrapper = useRef<HTMLDivElement>(null);
  const firstUpdate = useRef(true);

  const dropdownSuggestions = useMemo(
    () => searchSuggestions?.slice(0, MAX_SUGGESTIONS) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchSuggestions, value]
  );

  function clearSearch() {
    updateValue('');
    if (isWithDropdown) {
      setIsDropdownOpen(false);
    }
    clearErrors();
  }

  function clearErrors() {
    setIsPatternError(false);
    setIsMinLengthError(false);
    setIsMaxLengthError(false);
  }

  function isValidInputValue(val = value) {
    const trimmedValue = val.trim();
    const patternRegExp = new RegExp(pattern, 'g');

    clearErrors();

    if (minLengthErrorMessage && trimmedValue.length < minLength) {
      setIsMinLengthError(true);

      return false;
    }

    if (patternErrorMessage && !patternRegExp.test(trimmedValue)) {
      setIsPatternError(true);

      return false;
    }
    if (maxLengthErrorMessage && trimmedValue.length >= maxLength) {
      setIsMaxLengthError(true);

      return false;
    }

    return true;
  }

  function onValueChange(event: ChangeEvent<HTMLInputElement>) {
    const trimmedValue = event.target.value.trim();

    updateValue(event.target.value.slice(0, maxLength));

    if (
      isValidInputValue(trimmedValue) &&
      trimmedValue.length >= MIN_DROPDOWN_SHOW_VALUE_LENGTH &&
      isWithDropdown
    ) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  }

  function onSuggestionClick(suggestion: string) {
    updateValue(suggestion);
    onSubmit();
    if (isDropdownOpen) {
      closeAndClearSearch();
    }
  }

  function onEnterKeyPress(event: ReactKeyboardEvent) {
    if (event.key === 'Enter') {
      onSubmit(true);
    }

    if (dropdownSuggestions.length && isDropdownOpen && event.key === KEYBOARD_KEY_ARROW_DOWN) {
      event.preventDefault();
      setDropdownFocus(true);
    }
  }

  function onSubmit(closeDropdown?: boolean) {
    if (isValidInputValue()) {
      onSearch();

      if (closeDropdown && isDropdownOpen) {
        closeAndClearSearch();
      }
    }
  }

  function closeAndClearSearch() {
    setIsDropdownOpen(false);

    if (handleBlur) {
      handleBlur();
    }

    clearSearch();
  }

  useEffect(() => {
    // Initial check for search page, otherwise it would show minLength err
    if (value) {
      isValidInputValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    function hideDropdown(event: MouseEvent) {
      if (searchWrapper.current && !searchWrapper.current.contains(event.target as Node)) {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }

        closeAndClearSearch();
      }
    }

    if (!isFullWidth) {
      setIsFullWidth(true);
    }

    if (isWithDropdown && isNavigationInput) {
      document.addEventListener('click', hideDropdown);
      return () => document.removeEventListener('click', hideDropdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function hideDropdown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setIsDropdownOpen(false);
      }
    }

    if (isWithDropdown) {
      document.addEventListener('keydown', hideDropdown);
      return () => document.removeEventListener('keydown', hideDropdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDropdownFocus(false);
  }, [value, dropdownSuggestions]);

  return (
    <>
      <div
        className={`${styles.search} ${handleBlur ? styles['search--header'] : ''}`}
        data-testid="search-wrapper"
        ref={searchWrapper}
      >
        <input
          data-testid="search-input"
          className={`
            ${styles['search__input']}
            ${
              isPatternError || isMinLengthError || isMaxLengthError
                ? styles['search__input--error']
                : ''
            }
            ${isFullWidth ? styles['search__input--full-width'] : ''}
          `}
          style={{ height: inputHeight }}
          type="text"
          placeholder={placeholder}
          value={value}
          aria-label={`${t('telia-navigation:enter_search_phrase', { lng: language })}`}
          onChange={onValueChange}
          onKeyDown={onEnterKeyPress}
          autoFocus
          onFocus={() => setDropdownFocus(false)}
        />

        <button
          data-testid="search-clear-button"
          className={`${styles['search__button']} ${
            value.length ? styles['search__button--remove'] : ''
          }`}
          type="button"
          aria-label={`${t('telia-navigation:clear_search', { lng: language })}`}
          onClick={clearSearch}
        >
          <TeliaIcon size="sm" svg={close.svg} />
        </button>

        <button
          data-testid="search-button"
          className={`${styles['search__button']} ${styles['search__button--submit']}`}
          type="button"
          aria-label={`${t('telia-navigation:initialize_search', { lng: language })}`}
          onClick={() => onSubmit(true)}
        >
          <TeliaIcon size="sm" svg={search.svg} />
        </button>

        {isDropdownOpen && isWithDropdown && (
          <SearchDropdown
            language={language}
            searchPhrase={value}
            searchSuggestions={dropdownSuggestions}
            onSuggestionClick={onSuggestionClick}
            isAllResultsLinkHidden={isAllResultsLinkHidden}
            focusEnabled={dropdownFocus}
          />
        )}
      </div>

      {isPatternError && <InputError errorMessage={patternErrorMessage} />}
      {isMinLengthError && <InputError errorMessage={minLengthErrorMessage} />}
      {isMaxLengthError && <InputError errorMessage={maxLengthErrorMessage} />}
    </>
  );
}
