import { ReactNode } from 'react';

import { TranslationKeys } from 'i18n';
import { NonEmptyArray } from 'utils/models';

export interface InitialNavigationLink {
  name: string;
  slug: string;
  prefix?: TranslationKeys | '';
  suffix?: TranslationKeys;
  type?: 'level2';
  children?: NonEmptyArray<InitialNavigationLink>;
}

/*
 * id: helper prop for future loop render unique (key)
 * path: full path of  the route that will be later used for the router
 *       ex: "/sustainability/climate-and-circularity"
 * level: route level
 * slug: direct link with what is defined in Contentful
 * prefix: override the default mapping with desired translatable prefix
 *
 *
 * */
export interface NavigationLink extends InitialNavigationLink {
  id: number;
  path: string;
  directPath: string;
  level: number;
  children?: NonEmptyArray<NavigationLink>;
  element?: ReactNode;
  errorElement?: ReactNode;
}

export abstract class NavigationClass {
  abstract getLinks(): NavigationLink[];

  abstract getFlattenLinks(links?: NavigationLink[]): NavigationLink[];

  abstract findLinkBy(
    identifier: keyof Pick<NavigationLink, 'id' | 'path' | 'slug'>,
    identifierValue: string | number,
    links?: NavigationLink[]
  ): NavigationLink | undefined;
}
