import { TeliaButton, TeliaIcon } from '@teliads/components/react';
import { useTranslation } from 'react-i18next';

import { NavigationLink } from 'components/voca/navigation/navigation-stub/models';
import { TFunction } from 'i18n';

interface Props {
  navigateBack: () => void;
  selectedNavLink?: NavigationLink | null;
}

export default function MobileHeaderBackButton({ navigateBack, selectedNavLink }: Props) {
  const { t }: { t: TFunction } = useTranslation();
  const isHomePage = () => selectedNavLink!.name === t('link_home');

  return (
    <>
      {!!selectedNavLink && !isHomePage() && (
        <div>
          <TeliaButton
            ally-label="back to home view"
            className="mobile-header__back-button"
            data-testid="mobile-header-back-button"
            variant="tertiary-purple"
            size="sm"
            onClick={navigateBack}
          >
            <TeliaIcon name="arrow-left" size="md" />
          </TeliaButton>

          <div>{selectedNavLink.name}</div>
        </div>
      )}
    </>
  );
}
