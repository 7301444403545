import { SEOProps } from 'components/custom/SEO/SEO';
import { GetNewsroomAssemblyLevelCollectionQuery } from 'graphql/__generated/sdk';
import { BillboardContent, HeroBannerContent } from 'utils/models';

export enum BackendArticleType {
  NEWS = 'NEWS',
  PRESS = 'PRESS',
  PRESS_REGULATORY = 'REGULATORY_PRESS',
  JOB_AD = 'JOB_AD',
}

export enum NewsArticleSourceType {
  CONTENTFUL = 'CONTENTFUL',
  CISION = 'CISION',
}

export interface NewsArticles {
  __typename: BackendArticleType;
  type: BackendArticleType;
  date: string;
  title: string;
  source: NewsArticleSourceType;
  categories: string[];
  slug: string;
}

export interface NewsroomProps {
  heroBanner: HeroBannerContent;
  pressMedia: BillboardContent;
  mediaKit: BillboardContent;
  seoMetadata: SEOProps;
}

export type NewsroomCtfData = [GetNewsroomAssemblyLevelCollectionQuery];
