import { useEffect, useState } from 'react';

const useDebounceValue = (value: string, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState<string>(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 250);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

export default useDebounceValue;
