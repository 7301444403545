import { createUrlWithFocusArea } from './utils';

import { SEO_DEFAULT_DESCRIPTION, SEO_DEFAULT_TITLE } from 'components/custom/SEO/constants';
import { SeoMetadataFragment } from 'graphql/__generated/sdk';
import { ContentfulTypename } from 'pages/Collection/types';
import { URL_LANGUAGE_ENUM } from 'utils/constants';
import { ArticleEntry, TeaserEntry } from 'utils/contentful-models';
import { generateEntrySlug } from 'utils/mappers';
import type {
  BaseCardContent,
  ContentfulRichText,
  CtaContent,
  HeroBannerContent,
  ImageContent,
} from 'utils/models';

export interface Items<T> {
  items: T[];
}

export interface Image {
  image?: {
    url: string;
  };
  focusArea?: string;
  altText?: string;
}

interface BaseCard {
  title: string;
  url: string;
  image: Image | null;
  body?: any;
  category?: string;
  tagline?: string;
}

interface HeroBanner {
  title: string;
  preamble: ContentfulRichText;
  image: Image | null;
  videoUrl?: string;
  cta?: CtaContent;
}

interface Table {
  title: string;
  cta?: CtaContent;
  items?: any[];
}

interface BottomLink {
  text: string;
  url: string;
}

interface Billboard {
  title: string;
  image?: Image | null;
  preamble?: ContentfulRichText;
  cta?: CtaContent;
  bottomLinkEntries?: BottomLink[];
}

export function generateImage(image: Image): ImageContent {
  const url = image?.image?.url;

  return {
    url: url ? createUrlWithFocusArea(url, image?.focusArea) : '',
    altText: image?.altText || '',
    focusArea: image?.focusArea || '',
  };
}

export function generateBaseCard(card: BaseCard): BaseCardContent {
  return {
    title: card.title,
    url: card.url || '',
    image: generateImage(card.image || {}),
    body: card.body || '',
    category: card.category || '',
    tagline: card.tagline || '',
  };
}

export function generateHeroBanner(banner: HeroBanner): HeroBannerContent {
  return {
    title: banner.title,
    preamble: banner.preamble,
    image: generateImage(banner.image || {}),
    videoUrl: banner.videoUrl || '',
    cta: banner?.cta || {
      text: '',
      url: '',
    },
  };
}

export function generateBillboard(billboard: Billboard) {
  const bottomLinks = billboard.bottomLinkEntries?.map((link) => ({
    name: link.text || '',
    href: link.url || '',
  }));

  return {
    title: billboard.title,
    image: generateImage(billboard.image ? billboard.image : { image: { url: '' } }),
    preamble: billboard.preamble || '',
    cta: billboard.cta || {
      text: '',
      url: '',
    },
    bottomLinks: bottomLinks || [],
  };
}

export function generateTable(table: Table) {
  return {
    title: table.title,
    items: table.items || [],
    cta: table.cta || {
      text: '',
      url: '',
    },
  };
}

export function generateSeoMetadata({
  title = SEO_DEFAULT_TITLE,
  description = SEO_DEFAULT_DESCRIPTION,
  keywords = [],
  xRobotsTag = undefined,
  noIndex = false,
  noFollow = false,
  socialMediaTitle = SEO_DEFAULT_TITLE,
  socialMediaDescription = SEO_DEFAULT_DESCRIPTION,
  socialMediaImage = { __typename: 'Image', image: { __typename: 'Asset', url: '' } },
}: Partial<SeoMetadataFragment>) {
  const mainTitle = title || SEO_DEFAULT_TITLE;
  const mainDescription = description || SEO_DEFAULT_DESCRIPTION;

  return {
    title: mainTitle,
    meta: {
      description: mainDescription,
      keywords: keywords ? keywords.join(', ') : '',
      robotsTag: xRobotsTag || false,
      noIndex: noIndex,
      noFollow: noFollow,
    },
    socialMedia: {
      title: socialMediaTitle || mainTitle,
      description: socialMediaDescription || mainDescription,
      image: generateImage(socialMediaImage),
    },
  };
}

// todo refactor this to use simple mappings
function buildBaseCard(
  card: ArticleEntry | TeaserEntry,
  language: URL_LANGUAGE_ENUM
): BaseCardContent {
  switch (card.__typename) {
    case ContentfulTypename.ARTICLE: {
      const url = generateEntrySlug(card, language);

      return generateBaseCard({
        title: card.title,
        url,
        image: getFirstContentItem(card.images)!,
        body: card.preamble || '',
        category: card.category || '',
      });
    }

    case ContentfulTypename.TEASER: {
      return generateBaseCard({
        title: card.title,
        url: card.teaserUrl,
        image: { image: card.primaryMediaImage },
        body: card.preamble || '',
        category: '',
        tagline: card.tagline || '',
      });
    }
  }
}

export function prepareBaseCards(
  content: { items: (ArticleEntry | TeaserEntry)[] },
  language: URL_LANGUAGE_ENUM
) {
  return content.items.map((item) => buildBaseCard(item, language));
}

export function getFirstContentItem<T>(data: Items<T>): T {
  if (data.items.length) {
    return data.items[0];
  }

  return {} as T;
}
