import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { animationDurationMs } from 'utils/constants';

import './ModalOverlay.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ModalOverlay({ isOpen, onClose }: Props) {
  const overlayRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      nodeRef={overlayRef}
      in={isOpen}
      timeout={animationDurationMs}
      classNames="opacity"
      mountOnEnter
      unmountOnExit
    >
      <div
        ref={overlayRef}
        className="modal-overlay"
        data-testid="modal-overlay"
        aria-label="Modal overlay"
        tabIndex={-1}
        onClick={onClose}
      />
    </CSSTransition>
  );
}
