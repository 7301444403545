import i18next from 'i18next';

import { InitialNavigationLink, NavigationClass, NavigationLink } from './models';

import { TFunction, initI18n } from 'i18n';
import { URL_LANGUAGE_ENUM } from 'utils/constants';

initI18n();

function generateNavigationLinks(language: URL_LANGUAGE_ENUM): InitialNavigationLink[] {
  return [
    {
      name: i18next.t('navigation.about_the_company.title', { lng: language }),
      slug: i18next.t('navigation.about_the_company.slug', { lng: language }),
      children: [
        {
          name: i18next.t('navigation.this_is_telia.title', { lng: language }),
          slug: i18next.t('navigation.this_is_telia.slug', { lng: language }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.strategy.title', { lng: language }),
              slug: i18next.t('navigation.strategy.slug', { lng: language }),
              prefix: 'link_category',
            },
            {
              name: i18next.t('navigation.purpose_and_values.title', { lng: language }),
              slug: i18next.t('navigation.purpose_and_values.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.new_perspectives.title', { lng: language }),
              slug: i18next.t('navigation.new_perspectives.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.markets_and_brands.title', { lng: language }),
              slug: i18next.t('navigation.markets_and_brands.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.operations.title', { lng: language }),
              slug: i18next.t('navigation.operations.slug', { lng: language }),
              prefix: 'link_category',
            },
            {
              name: i18next.t('navigation.security_trend_report.title', { lng: language }),
              slug: i18next.t('navigation.security_trend_report.slug', { lng: language }),
              prefix: '',
            },
          ],
        },
        {
          name: i18next.t('navigation.corporate_governance.title', { lng: language }),
          slug: i18next.t('navigation.corporate_governance.slug', { lng: language }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.shareholders.title', { lng: language }),
              slug: i18next.t('navigation.shareholders.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.nomination_committee.title', { lng: language }),
              slug: i18next.t('navigation.nomination_committee.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.board_of_directors.title', { lng: language }),
              slug: i18next.t('navigation.board_of_directors.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.group_executive_management.title', { lng: language }),
              slug: i18next.t('navigation.group_executive_management.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.auditors.title', { lng: language }),
              slug: i18next.t('navigation.auditors.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.articles_of_association.title', { lng: language }),
              slug: i18next.t('navigation.articles_of_association.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.code_of_responsible_business_conduct.title', {
                lng: language,
              }),
              slug: i18next.t('navigation.code_of_responsible_business_conduct.slug', {
                lng: language,
              }),
              prefix: 'link_about_the_company',
              suffix: 'link_corporate_governance',
            },
            {
              name: i18next.t('navigation.remuneration.title', {
                lng: language,
              }),
              slug: i18next.t('navigation.remuneration.slug', {
                lng: language,
              }),
            },
          ],
        },
        {
          name: i18next.t('navigation.policies_and_ethics.title', {
            lng: language,
          }),
          slug: i18next.t('navigation.policies_and_ethics.slug', {
            lng: language,
          }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.public_policy.title', {
                lng: language,
              }),
              slug: i18next.t('navigation.public_policy.slug', {
                lng: language,
              }),
            },
            {
              name: i18next.t('navigation.ai_ethics.title', { lng: language }),
              slug: i18next.t('navigation.ai_ethics.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.mobile_communications_and_health.title', {
                lng: language,
              }),
              slug: i18next.t('navigation.mobile_communications_and_health.slug', {
                lng: language,
              }),
            },
          ],
        },
      ],
    },
    {
      name: i18next.t('navigation.newsroom.title', { lng: language }),
      slug: i18next.t('navigation.newsroom.slug', { lng: language }),
      children: [
        {
          name: i18next.t('navigation.media_bank.title', { lng: language }),
          slug: i18next.t('navigation.media_bank.slug', { lng: language }),
          prefix: 'link_category',

          children: [
            {
              name: i18next.t('navigation.logotypes.title', { lng: language }),
              slug: i18next.t('navigation.logotypes.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.group_executive_management.title', { lng: language }),
              slug: i18next.t('navigation.group_executive_management.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.board_of_directors.title', { lng: language }),
              slug: i18next.t('navigation.board_of_directors.slug', { lng: language }),
            },
          ],
        },
      ],
    },
    {
      name: i18next.t('navigation.investors.title', { lng: language }),
      slug: i18next.t('navigation.investors.slug', { lng: language }),
      children: [
        {
          name: '',
          slug: '',
          children: [
            {
              name: i18next.t('navigation.financial_overview.title', { lng: language }),
              slug: i18next.t('navigation.financial_overview.slug', { lng: language }),
              type: 'level2',
            },
            {
              name: i18next.t('navigation.financial_calendar.title', { lng: language }),
              slug: i18next.t('navigation.financial_calendar.slug', { lng: language }),
              type: 'level2',
            },
            {
              name: i18next.t('navigation.annual_general_meeting.title', { lng: language }),
              slug: i18next.t('navigation.annual_general_meeting.slug', { lng: language }),
              type: 'level2',
              prefix: '',
            },
          ],
        },
        {
          name: i18next.t('navigation.reports_and_presentations.title', { lng: language }),
          slug: i18next.t('navigation.reports_and_presentations.slug', { lng: language }),
          prefix: '',
          children: [
            {
              name: i18next.t('navigation.annual_sustainability_report.title', { lng: language }),
              slug: i18next.t('navigation.annual_sustainability_report.slug', { lng: language }),
              prefix: 'link_investors',
            },
          ],
        },
        {
          name: i18next.t('navigation.share_related_information.title', { lng: language }),
          slug: i18next.t('navigation.share_related_information.slug', { lng: language }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.shareholdings.title', { lng: language }),
              slug: i18next.t('navigation.shareholdings.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.dividend.title', { lng: language }),
              slug: i18next.t('navigation.dividend.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.share_price.title', { lng: language }),
              slug: i18next.t('navigation.share_price.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.trading_volume.title', { lng: language }),
              slug: i18next.t('navigation.trading_volume.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.listings.title', { lng: language }),
              slug: i18next.t('navigation.listings.slug', { lng: language }),
            },
          ],
        },
        {
          name: i18next.t('navigation.other_financial_information.title', { lng: language }),
          slug: i18next.t('navigation.other_financial_information.slug', { lng: language }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.outlook.title', { lng: language }),
              slug: i18next.t('navigation.outlook.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.consensus_estimates.title', { lng: language }),
              slug: i18next.t('navigation.consensus_estimates.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.debt_financing.title', { lng: language }),
              slug: i18next.t('navigation.debt_financing.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.spectrum_holdings.title', { lng: language }),
              slug: i18next.t('navigation.spectrum_holdings.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.pre_earning_items.title', { lng: language }),
              slug: i18next.t('navigation.pre_earning_items.slug', { lng: language }),
            },
          ],
        },
      ],
    },
    {
      name: i18next.t('navigation.sustainability.title', { lng: language }),
      slug: i18next.t('navigation.sustainability.slug', { lng: language }),
      children: [
        {
          name: '',
          slug: '',
          children: [
            {
              name: i18next.t('navigation.climate_and_circularity.title', { lng: language }),
              slug: i18next.t('navigation.climate_and_circularity.slug', { lng: language }),
              type: 'level2',
              prefix: 'link_category',
            },
            {
              name: i18next.t('navigation.digital_inclusion.title', { lng: language }),
              slug: i18next.t('navigation.digital_inclusion.slug', { lng: language }),
              type: 'level2',
              prefix: 'link_category',
            },
            {
              name: i18next.t('navigation.privacy_and_security.title', { lng: language }),
              slug: i18next.t('navigation.privacy_and_security.slug', { lng: language }),
              type: 'level2',
              prefix: 'link_category',
            },
            {
              name: i18next.t('navigation.goals_and_performance.title', { lng: language }),
              slug: i18next.t('navigation.goals_and_performance.slug', { lng: language }),
              type: 'level2',
            },
          ],
        },
        {
          name: i18next.t('navigation.more_sustainability_topics.title', { lng: language }),
          slug: i18next.t('navigation.more_sustainability_topics.slug', { lng: language }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.human_rights.title', { lng: language }),
              slug: i18next.t('navigation.human_rights.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.responsible_sourcing.title', { lng: language }),
              slug: i18next.t('navigation.responsible_sourcing.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.anti_bribery_and_corruption.title', { lng: language }),
              slug: i18next.t('navigation.anti_bribery_and_corruption.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.childrens_rights.title', { lng: language }),
              slug: i18next.t('navigation.childrens_rights.slug', { lng: language }),
              prefix: 'link_category',
            },
            {
              name: i18next.t('navigation.diversity_and_inclusion.title', { lng: language }),
              slug: i18next.t('navigation.diversity_and_inclusion.slug', { lng: language }),
              prefix: 'link_category',
            },
            {
              name: i18next.t('navigation.freedom_of_expression.title', { lng: language }),
              slug: i18next.t('navigation.freedom_of_expression.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.responsible_tax_practices.title', { lng: language }),
              slug: i18next.t('navigation.responsible_tax_practices.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.health_and_well_being.title', { lng: language }),
              slug: i18next.t('navigation.health_and_well_being.slug', { lng: language }),
            },
          ],
        },
        {
          name: i18next.t('navigation.reporting.title', { lng: language }),
          slug: i18next.t('navigation.reporting.slug', { lng: language }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.law_enforcement_disclosure_report.title', {
                lng: language,
              }),
              slug: i18next.t('navigation.law_enforcement_disclosure_report.slug', {
                lng: language,
              }),
            },
            {
              name: i18next.t('navigation.management_system.title', { lng: language }),
              slug: i18next.t('navigation.management_system.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.reports_and_presentations.title', { lng: language }),
              slug: i18next.t('navigation.reports_and_presentations.slug', { lng: language }),
              prefix: '',
            },
          ],
        },
      ],
    },
    {
      name: i18next.t('navigation.careers.title', { lng: language }),
      slug: i18next.t('navigation.careers.slug', { lng: language }),
      children: [
        {
          name: i18next.t('navigation.open_positions.title', { lng: language }),
          slug: i18next.t('navigation.open_positions.slug', { lng: language }),
          prefix: 'link_careers',
        },
        // TODO - Uncomment once content is ready in prod env
        // {
        //   name: i18next.t('navigation.careers_teams.title', { lng: language }),
        //   slug: i18next.t('navigation.careers_teams.slug', { lng: language }),
        //   prefix: 'link_careers',
        // },
        // TO-DO: Uncomment once content is ready in prod env
        // {
        //   name: i18next.t('navigation.about_life_at_telia.title', { lng: language }),
        //   slug: i18next.t('navigation.about_life_at_telia.slug', { lng: language }),
        //   prefix: 'link_careers',
        // },
      ],
    },
    {
      name: i18next.t('navigation.solutions.title', { lng: language }),
      slug: i18next.t('navigation.solutions.slug', { lng: language }),
      children: [
        {
          name: i18next.t('navigation.solutions_global_new.title', { lng: language }),
          slug: i18next.t('navigation.solutions_global_new.slug', { lng: language }),
          prefix: 'link_solutions',
          children: [
            {
              name: i18next.t('navigation.mobility.title', { lng: language }),
              slug: i18next.t('navigation.mobility.slug', { lng: language }),
              prefix: 'link_solutions',
              suffix: 'link_global',
            },
            {
              name: i18next.t('navigation.network_solutions.title', { lng: language }),
              slug: i18next.t('navigation.network_solutions.slug', { lng: language }),
              prefix: 'link_solutions',
              suffix: 'link_global',
            },
            {
              name: i18next.t('navigation.security_global_business.title', { lng: language }),
              slug: i18next.t('navigation.security_global_business.slug', { lng: language }),
              prefix: 'link_solutions',
              suffix: 'link_global',
            },
            {
              name: i18next.t('navigation.cloud.title', { lng: language }),
              slug: i18next.t('navigation.cloud.slug', { lng: language }),
              prefix: 'link_solutions',
              suffix: 'link_global',
            },
            {
              name: i18next.t('navigation.industry_digitalization.title', { lng: language }),
              slug: i18next.t('navigation.industry_digitalization.slug', { lng: language }),
              prefix: 'link_solutions',
              suffix: 'link_global',
            },
            {
              name: i18next.t('navigation.contact_center_solutions.title', { lng: language }),
              slug: i18next.t('navigation.contact_center_solutions.slug', { lng: language }),
              prefix: 'link_solutions',
              suffix: 'link_global',
            },
            {
              name: i18next.t('navigation.customer_stories.title', { lng: language }),
              slug: i18next.t('navigation.customer_stories.slug', { lng: language }),
              prefix: 'link_articles',
              type: 'level2',
            },
            {
              name: i18next.t('navigation.insights_inspiration.title', { lng: language }),
              slug: i18next.t('navigation.insights_inspiration.slug', { lng: language }),
              prefix: 'link_articles',
              type: 'level2',
            },
            {
              name: i18next.t('navigation.about_us.title', { lng: language }),
              slug: i18next.t('navigation.about_us.slug', { lng: language }),
              prefix: 'link_solutions',
              suffix: 'link_global',
              type: 'level2',
            },
          ],
        },
      ],
    },
    {
      name: i18next.t('navigation.contact_us.title', { lng: language }),
      slug: i18next.t('navigation.contact_us.slug', { lng: language }),
      prefix: 'link_category',
      children: [
        {
          name: '',
          slug: '',
          children: [
            {
              name: i18next.t('navigation.press_and_media.title', { lng: language }),
              slug: i18next.t('navigation.press_and_media.slug', { lng: language }),
              type: 'level2',
            },
            {
              name: i18next.t('navigation.investor_relations.title', { lng: language }),
              slug: i18next.t('navigation.investor_relations.slug', { lng: language }),
              type: 'level2',
            },
            {
              name: i18next.t('navigation.customer_service.title', { lng: language }),
              slug: i18next.t('navigation.customer_service.slug', { lng: language }),
              type: 'level2',
            },
          ],
        },
        {
          name: i18next.t('navigation.for_suppliers.title', { lng: language }),
          slug: i18next.t('navigation.for_suppliers.slug', { lng: language }),
          prefix: 'link_category',
          children: [
            {
              name: i18next.t('navigation.how_to_invoice.title', { lng: language }),
              slug: i18next.t('navigation.how_to_invoice.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.documents.title', { lng: language }),
              slug: i18next.t('navigation.documents.slug', { lng: language }),
            },
            {
              name: i18next.t('navigation.responsible_sourcing.title', { lng: language }),
              slug: i18next.t('navigation.responsible_sourcing.slug', { lng: language }),
            },
          ],
        },
      ],
    },
  ];
}

const navLinksMap = new Map<URL_LANGUAGE_ENUM, InitialNavigationLink[]>([
  [URL_LANGUAGE_ENUM.ENGLISH, generateNavigationLinks(URL_LANGUAGE_ENUM.ENGLISH)],
  [URL_LANGUAGE_ENUM.SWEDISH, generateNavigationLinks(URL_LANGUAGE_ENUM.SWEDISH)],
]);

class NavigationStubClass extends NavigationClass {
  private navigationLinks: NavigationLink[] = [];
  private id = 1;
  private readonly dynamicTranslate: TFunction;

  constructor(language: URL_LANGUAGE_ENUM) {
    super();
    this.dynamicTranslate = i18next.getFixedT(language);
    this.initNavLinks(language);
    this.buildNavigationLinks(language);
  }

  private buildNavigationLinks(
    language: URL_LANGUAGE_ENUM,
    navLinks = this.navigationLinks,
    parentPath = `/${language}`,
    level = 1
  ): void {
    navLinks.forEach((route) => {
      const { children, slug, prefix, suffix } = route;

      this.id += 1;

      const childPathBasedOnLevel = `${parentPath}/${slug}`;

      route.level = level;
      route.path = childPathBasedOnLevel;
      route.id = this.id;
      route.directPath = this.generateRouteDirectPath(
        level,
        language,
        slug,
        childPathBasedOnLevel,
        prefix,
        suffix
      );

      if (children) {
        const newLevel = level + 1;
        this.buildNavigationLinks(language, children, childPathBasedOnLevel, newLevel);
        children.unshift({
          name: route.name,
          path: '',
          directPath: this.generateRouteDirectPath(level, language, slug, '', prefix),
          level,
          id: ++this.id,
          slug: route.slug,
        });
      }
    });
  }

  private generateRouteDirectPath(
    level: number,
    language: URL_LANGUAGE_ENUM,
    routeSlug: string,
    childPathBasedOnLevel: string,
    prefix: InitialNavigationLink['prefix'],
    suffix?: InitialNavigationLink['suffix']
  ): string {
    if (prefix === '') {
      return `/${language}/${routeSlug}`;
    }

    if (suffix && prefix) {
      return `/${language}/${this.dynamicTranslate(prefix)}/${this.dynamicTranslate(
        suffix
      )}/${routeSlug}`;
    }

    if (prefix && !suffix) {
      return `/${language}/${this.dynamicTranslate(prefix)}/${routeSlug}`;
    }

    return level > 1
      ? `/${language}/${this.dynamicTranslate('link_articles')}/${routeSlug}`
      : childPathBasedOnLevel;
  }

  private initNavLinks(language: URL_LANGUAGE_ENUM): void {
    this.navigationLinks = JSON.parse(
      JSON.stringify(navLinksMap.get(language))
    ) as NavigationLink[];
  }

  public getLinks(): NavigationLink[] {
    return this.navigationLinks;
  }

  public getFlattenLinks(links = this.navigationLinks): NavigationLink[] {
    return links.flatMap((link) => {
      const { children } = link;
      const newLink = { ...link };
      return children?.length ? [newLink, ...this.getFlattenLinks(children)] : newLink;
    });
  }

  public findLinkBy(
    identifier: keyof Pick<NavigationLink, 'id' | 'path' | 'slug'>,
    identifierValue: string | number,
    links = this.navigationLinks
  ): NavigationLink | undefined {
    if (identifierValue === '') {
      return {
        name: this.dynamicTranslate('link_home'),
        children: this.navigationLinks,
      } as unknown as NavigationLink;
    }

    for (const node of links) {
      if (node[identifier] === identifierValue) return node;

      if (node.children) {
        const found = this.findLinkBy(identifier, identifierValue, node.children);
        if (found) return found;
      }
    }
  }
}

export default new Map<URL_LANGUAGE_ENUM, NavigationClass>([
  [URL_LANGUAGE_ENUM.ENGLISH, new NavigationStubClass(URL_LANGUAGE_ENUM.ENGLISH)],
  [URL_LANGUAGE_ENUM.SWEDISH, new NavigationStubClass(URL_LANGUAGE_ENUM.SWEDISH)],
]);
