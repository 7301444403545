import { ResultTypeFilter } from '../components/custom/filters/models';

import { PaginatedResults } from 'pages/Search/models';

// @ts-ignore
const SEARCH_ENDPOINT: string = process.env.REACT_APP_SEARCH_API_ENDPOINT;

//@ts-ignore
const SEARCH_SUGGESTIONS_ENDPOINT: string = process.env.REACT_APP_SEARCH_SUGGESTIONS_API_ENDPOINT;

const getHttpErrorMessage = (status: number) => `HTTP error! Status: ${status}`;

type SearchQuery = {
  page: number;
  query: string;
  language: string;
  type?: ResultTypeFilter;
};

const HEADERS = {
  'Content-Type': 'application/json',
};

export const fetchSearchResults = async (query: SearchQuery): Promise<PaginatedResults> => {
  const response = await fetch(SEARCH_ENDPOINT, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(query),
  });

  if (response.status === 400) {
    return { results: [], totalResults: 0, totalPages: 0 };
  }

  if (!response.ok) {
    throw new Error(getHttpErrorMessage(response.status));
  }

  return await response.json();
};

export const fetchSearchSuggestions = async (searchQuery: string, language: string) => {
  const response = await fetch(
    `${SEARCH_SUGGESTIONS_ENDPOINT}?query=${searchQuery}&language=${language.toUpperCase()}`
  );

  if (!response.ok) {
    throw new Error(getHttpErrorMessage(response.status));
  }

  return await response.json();
};
