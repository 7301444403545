import { KeyboardEvent as ReactKeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KEYBOARD_KEY_ARROW_DOWN, KEYBOARD_KEY_ARROW_UP } from '../../constants';
import { Variant } from '../../types/common';
import NavLink from '../NavLink/NavLink';

import styles from './SearchDropdown.module.scss';

type Props = {
  language?: string;
  searchPhrase: string;
  searchSuggestions?: string[];
  onSuggestionClick?: (suggestion: string) => void;
  isAllResultsLinkHidden?: boolean;
  focusEnabled?: boolean;
};

export default function SearchDropdown({
  language = 'en',
  searchPhrase,
  onSuggestionClick,
  searchSuggestions,
  isAllResultsLinkHidden = false,
  focusEnabled,
}: Props) {
  const [focusedIndex, setFocusedIndex] = useState(0);

  const refs: HTMLAnchorElement[] = [];

  const { t } = useTranslation();

  function processSuggestionLength(keyword: string) {
    const MAX_CHARACTERS = 100;

    if (keyword.length > MAX_CHARACTERS) {
      return keyword.substring(0, MAX_CHARACTERS).trim() + '...';
    }

    return keyword.trim();
  }

  useEffect(() => {
    if (searchSuggestions && focusEnabled) {
      refs[focusedIndex].focus();
    } else {
      setFocusedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusEnabled, focusedIndex]);

  const onEnterKeyPress = (e: ReactKeyboardEvent) => {
    if (searchSuggestions?.length) {
      if (e.key === KEYBOARD_KEY_ARROW_DOWN) {
        e.preventDefault();
        const indexToFocus = focusedIndex + 1;

        if (indexToFocus < searchSuggestions.length) {
          setFocusedIndex(indexToFocus);
        } else {
          setFocusedIndex(0);
        }
      }

      if (e.key === KEYBOARD_KEY_ARROW_UP) {
        e.preventDefault();
        const indexToFocus = focusedIndex - 1;

        if (indexToFocus >= 0) {
          setFocusedIndex(indexToFocus);
        } else {
          setFocusedIndex(searchSuggestions.length - 1);
        }
      }
    }
  };

  return (
    <section
      onKeyDown={onEnterKeyPress}
      className={styles['search-dropdown']}
      data-testid="search-dropdown"
    >
      {searchPhrase.length >= 2 && searchSuggestions && searchSuggestions.length > 0 && (
        <>
          <li className={styles['search-dropdown__title']}>
            {t('telia-navigation:dropdown.keywords', { lng: language })}
          </li>

          {searchSuggestions.map((keyword) => (
            <li
              key={keyword}
              className={styles['search-dropdown__item']}
              data-testid="search-dropdown-keyword"
            >
              <NavLink
                ref={(element: HTMLAnchorElement) => refs.push(element)}
                onClick={() => (onSuggestionClick ? onSuggestionClick(keyword) : null)}
                url={`/${language}/${t('telia-navigation:dropdown.link_search_results', {
                  lng: language,
                })}?query=${keyword}`}
                title={processSuggestionLength(keyword)}
              />
            </li>
          ))}
        </>
      )}

      {searchPhrase.length >= 2 && searchSuggestions && searchSuggestions.length === 0 && (
        <li
          className={`${styles['search-dropdown__title']} ${styles['search-dropdown__title--error']}`}
          data-testid="search-dropdown-error-title"
        >
          {t('telia-navigation:dropdown.no_keywords_were_found', { lng: language })}
        </li>
      )}

      {searchPhrase.length >= 2 && !searchSuggestions && (
        <li
          className={`${styles['search-dropdown__title']} ${styles['search-dropdown__title--error']}`}
          data-testid="search-dropdown-error-title"
        >
          {t('telia-navigation:dropdown.searching', { lng: language })}
        </li>
      )}

      {!isAllResultsLinkHidden && (
        <>
          <div className={styles['search-dropdown__line-break']} />
          <li data-testid="search-all-results" className={styles['search-dropdown__item']}>
            <NavLink
              url={`/${language}/${t('telia-navigation:dropdown.link_search_results', {
                lng: language,
              })}?query=${searchPhrase}`}
              title={t('telia-navigation:dropdown.all_results', { lng: language })}
              variant={Variant.LINK}
            />
          </li>
        </>
      )}
    </section>
  );
}
