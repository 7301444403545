import { getFixedT } from 'i18next';
import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import RootLayout from 'components/custom/root-layout/RootLayout';
import { TFunction } from 'i18n';
import Page404 from 'pages/Page404';
import { URL_LANGUAGE_ENUM } from 'utils/constants';
import { getAvailableSlugs } from 'utils/translation.utils';

const PageLevel0 = lazy(() => import('pages/PageLevel0'));
const PageLevel1 = lazy(() => import('pages/PageLevel1'));
const Article = lazy(() => import('pages/Article'));
const Newsroom = lazy(() => import('pages/Newsroom'));
const NewsArticle = lazy(() => import('pages/NewsArticle'));
const ErrorScreenSample = lazy(() => import('pages/ErrorScreenSample'));
const ReportsAndPresentations = lazy(() => import('pages/ReportsAndPresentations'));
const Collection = lazy(() => import('pages/Collection'));
const AnnualGeneralMeeting = lazy(() => import('pages/AnnualGeneralMeeting'));
const Person = lazy(() => import('pages/Person'));
const PressRelease = lazy(() => import('pages/PressRelease'));
const Webcast = lazy(() => import('pages/Webcast'));
const OpenPositions = lazy(() => import('pages/OpenPositions'));
const Careers = lazy(() => import('pages/CareersPage'));
const CareersTeamsPage = lazy(() => import('pages/CareersTeamsPage'));
const SingleJobAd = lazy(() => import('pages/SingleJobAd'));
const Search = lazy(() => import('pages/Search'));
const CodeOfConduct = lazy(() => import('pages/CodeOfConduct'));
const AnnualSustainabilityReport = lazy(() => import('pages/AnnualSustainabilityReport'));
const CareersLifeAtTelia = lazy(() => import('pages/CareersLifeAtTelia'));
const RedirectPage = lazy(() => import('pages/RedirectPage/RedirectPage'));
const SolutionsFlexiblePage = lazy(() => import('pages/FlexibleSolutionsTemplate'));
const Level1BtPage = lazy(() => import('pages/PageLevel1BT'));

const getLocalisedRoutes = (language: URL_LANGUAGE_ENUM) => {
  const dynamicTranslate: TFunction = getFixedT(language);

  return [
    {
      // /en
      path: '',
      element: <PageLevel0 />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/[level-1-slug]
      path: `/${language}/:slug`,
      element: <PageLevel1 />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/newsroom
      path: `/${language}/${dynamicTranslate('link_newsroom')}`,
      element: <Newsroom />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/solutions/[slug]
      path: `/${language}/${dynamicTranslate('link_solutions')}/:slug`,
      element: <Level1BtPage />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/about-the-company/corporate-governance/code-of-responsible-business-conduct
      path: `/${language}/${dynamicTranslate('link_about_the_company')}/${dynamicTranslate(
        'link_corporate_governance'
      )}/${dynamicTranslate('link_code_of_conduct')}`,
      element: <CodeOfConduct />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/reports-and-presentations
      path: `/${language}/${dynamicTranslate('link_reports_and_presentations')}`,
      element: <ReportsAndPresentations />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/annual-general-meeting
      path: `/${language}/${dynamicTranslate('link_annual_general_meeting')}`,
      element: <AnnualGeneralMeeting />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/search
      path: `/${language}/${dynamicTranslate('link_search_results')}`,
      element: <Search slugs={getAvailableSlugs('link_search_results')} />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/security-trend-report
      path: `/${language}/${dynamicTranslate('link_security_report')}`,
      element: <SolutionsFlexiblePage Slug={dynamicTranslate('link_security_report')} />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/articles/[article-slug]
      path: `/${language}/${dynamicTranslate('link_articles')}/:articleSlug`,
      element: <Article />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/news-articles/[news-article-slug]
      path: `/${language}/${dynamicTranslate('link_news_articles')}/:newsArticleSlug`,
      element: <NewsArticle />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/press-releases/[press-release-article-slug]
      path: `/${language}/${dynamicTranslate('link_press_releases')}/:pressReleaseArticleSlug`,
      element: <PressRelease />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/news/[press-release-article-slug]
      path: `/${language}/${dynamicTranslate('link_news')}/:pressReleaseArticleSlug`,
      element: <PressRelease />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/webcast/[webcast-article-slug]
      path: `/${language}/${dynamicTranslate('link_webcast')}/:webcastArticleSlug`,
      element: <Webcast />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/board-of-directors/[person-slug]
      path: `/${language}/${dynamicTranslate('link_board_of_directors')}/:personSlug`,
      element: <Person />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/group-executive-management/[person-slug]
      path: `/${language}/${dynamicTranslate('link_group_executive_management')}/:personSlug`,
      element: <Person />,
      errorElement: <ErrorScreenSample />,
    },
    // TO-DO Revert once content for new careers page is ready
    {
      // /en/careers-new
      // TODO: Update to regular /careers link once allowed by PM
      path: `/${language}/${dynamicTranslate('link_careers_new')}`,
      element: <Careers />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/careers/open-positions
      path: `/${language}/${dynamicTranslate('link_careers')}/${dynamicTranslate(
        'link_open_positions'
      )}`,
      element: <OpenPositions />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/careers/open-positions/[job-posting-slug]
      path: `/${language}/${dynamicTranslate('link_careers')}/${dynamicTranslate(
        'link_open_positions'
      )}/:jobPostingSlug`,
      element: <SingleJobAd />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/solutions/global/[solutions-flexible-slug]
      path: `/${language}/${dynamicTranslate('link_solutions')}/${dynamicTranslate(
        'link_global'
      )}/:slug`,
      element: <SolutionsFlexiblePage />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/category/[collection-page-slug]
      path: `/${language}/${dynamicTranslate('link_category')}/:collectionPageSlug`,
      element: <Collection />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/investors/annual-sustainability-report
      path: `/${language}/${dynamicTranslate('link_investors')}/${dynamicTranslate(
        'link_annual_sustainability_report'
      )}`,
      element: <AnnualSustainabilityReport />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/careers/teams
      path: `/${language}/${dynamicTranslate('link_careers')}/${dynamicTranslate(
        'link_careers_teams'
      )}`,
      element: <CareersTeamsPage />,
      errorElement: <ErrorScreenSample />,
    },
    {
      // /en/careers/about-life-at-telia
      path: `/${language}/${dynamicTranslate('link_careers')}/${dynamicTranslate(
        'link_about_life_at_telia'
      )}`,
      element: <CareersLifeAtTelia />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ];
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to={`/${URL_LANGUAGE_ENUM.ENGLISH}`} replace />,
  },
  {
    path: `/${URL_LANGUAGE_ENUM.ENGLISH}`,
    element: <RootLayout />,
    children: getLocalisedRoutes(URL_LANGUAGE_ENUM.ENGLISH),
    errorElement: <ErrorScreenSample />,
  },
  {
    path: `/${URL_LANGUAGE_ENUM.SWEDISH}`,
    element: <RootLayout />,
    children: getLocalisedRoutes(URL_LANGUAGE_ENUM.SWEDISH),
    errorElement: <ErrorScreenSample />,
  },
  {
    path: '/select-location',
    element: <RedirectPage />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
];

export const router = createBrowserRouter(routes);
