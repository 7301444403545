import { TeliaIcon, TeliaLink } from '@teliads/components/react';

import type { AccordionContentProps } from './types';
import './AccordionContent.scss';

import { CONTENTFUL_ASSET_TYPE } from 'utils/constants';

export default function AccordionContent({ title, url, contentType }: AccordionContentProps) {
  return (
    <section className="accordion-content">
      <TeliaIcon
        name={contentType === CONTENTFUL_ASSET_TYPE.PDF ? 'doc' : 'play'}
        allyTitle={`${contentType} icon`}
        dataTestid="telia-icon"
      />
      <TeliaLink
        href={url}
        target="_blank"
        download={contentType === 'webcast' ? undefined : title}
      >
        {title}
      </TeliaLink>
    </section>
  );
}
