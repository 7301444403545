import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

type ContextType = {
  headerHeight: number;
  setHeaderHeight: Dispatch<SetStateAction<number>>;
};

const HeaderContext = createContext<ContextType | null>(null);

export const HeaderProvider = ({ children }: PropsWithChildren) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const value = useMemo(() => ({ headerHeight, setHeaderHeight }), [headerHeight]);

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

export const useHeader = (): ContextType => useContext(HeaderContext) as ContextType;
