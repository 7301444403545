import '@teliads/components/dist/telia-component-library/telia-component-library.css';
import { registerIconsBundle } from '@teliads/components/icons';
import { TeliaVocaStats } from '@teliads/components/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { polyfill } from 'seamless-scroll-polyfill';

import { ApolloCustomProvider } from './apollo';
import './index.scss';
import { reportWebVitals } from './reportWebVitals';

import App from 'App';
import { HeaderProvider } from 'components/custom/Navigation';

polyfill();

const queryClient = new QueryClient();
const isDevEnv = process.env.NODE_ENV === 'development';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const mobileDetectorDiv = document.createElement('div');
mobileDetectorDiv.setAttribute('data-mobile-detector', '');
document.body.prepend(mobileDetectorDiv);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ApolloCustomProvider>
          {isDevEnv && <TeliaVocaStats project="teliacompany-global" country="Global" />}
          <HeaderProvider>
            <App />
          </HeaderProvider>
        </ApolloCustomProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);

registerIconsBundle();
reportWebVitals();
