// @ts-nocheck
import { TeliaAccordionItem, TeliaHeading } from '@teliads/components/react';
import { useCallback, useEffect, useState, useRef } from 'react';
import './DatablockFunctional.scss';

import useHorizontalScrollGradient from 'utils/hooks/useHorizontalScrollGradient/useHorizontalScrollGradient';

type Props = {
  widget: string | undefined;
  className?: string;
  locale: string;
  token: string | undefined;
  title: string;
};

const DatablocksWidget = ({ widget, className, locale, token, title }: Props) => {
  const [query] = useState(`widget-${widget}-${token}-${Date.now().toString()}`);
  const datablocksRef = useRef(null);
  const accordionRef = useRef(null);

  useHorizontalScrollGradient(datablocksRef);

  const injectWidget = useCallback(() => {
    if (widget && token) {
      const DB_URL = 'https://widget.datablocks.se/api/rose';
      if (!window._MF) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `${DB_URL}/assets/js/loader-v5.js`;
        script.nonce = 'datablocks/widget';
        document.body.appendChild(script);
        window._MF = {
          data: [],
          url: DB_URL,
          ready: false,
          render: () => (window._MF.ready = true),
          push: function (conf) {
            this.data.push(conf);
          },
        };
      }
      window._MF.push({ query: `#${query}`, widget, locale, token });
    }
  }, [locale, query, token, widget]);

  useEffect(() => {
    injectWidget();

    const stylesheet = new CSSStyleSheet();
    stylesheet.replaceSync(
      `.telia-accordion-item { --vds-accordion-item-content-max-width: 100% !important; }`
    );

    if (accordionRef.current?.shadowRoot?.adoptedStyleSheets) {
      accordionRef.current.shadowRoot.adoptedStyleSheets.push(stylesheet);
    }
  }, [injectWidget]);

  return (
    <>
      {widget !== 'kpi-consensus-last-updated' && widget !== 'kpi-graph' ? (
        <TeliaAccordionItem heading={title} ref={accordionRef}>
          <div id={query} className={className || 'datablocks-widget'} ref={datablocksRef} />
        </TeliaAccordionItem>
      ) : (
        <div className="datablocks-widget__wrapper">
          <TeliaHeading tag="h2">{title}</TeliaHeading>
          <div id={query} className={className} ref={datablocksRef} />
        </div>
      )}
    </>
  );
};

export default DatablocksWidget;
