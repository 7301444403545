import React, { RefObject } from 'react';

import { INTERACTIVE_ELEMENTS } from './constants';

export function focusLock(e: React.KeyboardEvent | KeyboardEvent, elementRef: RefObject<any>) {
  if (elementRef.current && e.key === 'Tab') {
    const focusableContent = elementRef.current.querySelectorAll(INTERACTIVE_ELEMENTS);
    const firstFocusableElement = focusableContent[0] as HTMLElement;
    const lastFocusableElement = focusableContent[focusableContent.length - 1] as HTMLElement;

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  }
}

export function focusFirstInteractiveElement(elementRef: RefObject<any>) {
  if (elementRef.current) {
    const firstElement = elementRef.current.querySelector(INTERACTIVE_ELEMENTS) as HTMLElement;
    firstElement.focus();
  }
}
