import { TeliaIcon } from '@teliads/components/react';
import { IconName } from '@teliads/icons/dist/main/Icons';
import { NavLink } from 'react-router-dom';

import styles from './NavItemLink.module.scss';

type Props = {
  url: string;
  title: string;
  variant?: 'primary' | 'secondary';
  ariaLabel?: string;
  onClick?: () => void;
  icon?: string | undefined;
};

export default function NavItemLink({
  url,
  title,
  variant = 'primary',
  ariaLabel,
  onClick,
  icon,
}: Props) {
  const classNames = `${styles['nav-item-link']} ${styles[`nav-item-link--${variant}`]}`;

  return (
    <NavLink
      data-testid="nav-item-link"
      className={({ isActive }) =>
        isActive ? `${classNames} ${styles['nav-item-link--active']}` : classNames
      }
      to={url}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <div className={styles['nav-item-content']}>
        {icon && (
          <>
            <TeliaIcon size="sm" name={icon as IconName} />
            <span className={styles['nav-item-spacer']}></span>
          </>
        )}
        {title}
      </div>
    </NavLink>
  );
}
