import {
  BLOCKS,
  Document,
  EMPTY_DOCUMENT,
  helpers,
  TopLevelBlock,
} from '@contentful/rich-text-types';
import i18next from 'i18next';

import type { Section } from 'components/custom/content-block/models';
import {
  ArticleFragment,
  NewsArticleArticleFragment,
  RelatedArticlesFromStandaloneTagsFragment,
} from 'graphql/__generated/sdk';
import { TFunction } from 'i18n';
import { ContentfulTypename, SectionCard } from 'pages/Collection/types';
import { BackendArticleType, NewsArticleSourceType } from 'pages/Newsroom/models';
import {
  ACCEPTED_ARTICLE_CONTENTFUL_TAGS_ENUM,
  ACCEPTED_ARTICLE_CONTENTFUL_TAGS_LIST,
  ACCEPTED_ARTICLE_NEWS_TAGS_LIST,
  maxNrOfRelatedArticlesPerSection,
  URL_LANGUAGE_ENUM,
} from 'utils/constants';
import { ContentfulSystemTag, EntrySlugProps } from 'utils/contentful-models';
import { PERSON_CUSTOM_TAG } from 'utils/models';
import { updateTextToSentenceCase, formatDateToUTC } from 'utils/utils';

const createCompletelyEmptyDocument = () => ({ ...EMPTY_DOCUMENT, content: [] });

function mapAcceptedTagsFilter(
  article: NewsArticleArticleFragment | ArticleFragment
): { value: string }[] {
  return article.tagsCollection.items
    .filter((tag) => ACCEPTED_ARTICLE_NEWS_TAGS_LIST.includes(tag.value))
    .map((tag) => ({ value: tag.value }));
}

function createSection(title: string, document?: Document): Section {
  return {
    title,
    document: document || createCompletelyEmptyDocument(),
  };
}

function createDocument(content?: TopLevelBlock[]): Document {
  return content
    ? { nodeType: BLOCKS.DOCUMENT, content, data: {} }
    : createCompletelyEmptyDocument();
}

function addContentToSection(sections: Section[], index: number, content: TopLevelBlock): void {
  const lastSection = sections[index];

  lastSection.document.content.push(content);
}

function getTextFromHeadingNode(headingNode: TopLevelBlock): string {
  if (headingNode.content.length && helpers.isText(headingNode.content[0])) {
    return headingNode.content[0].value;
  }
  return '';
}

const isFirstSection = (sections: Section[]) => !sections.length;

export function generateContentBlocks(articleBody?: Document): Section[] {
  if (!articleBody) return [];

  return articleBody.content.reduce((sections: Section[], block: TopLevelBlock) => {
    if (block.nodeType === BLOCKS.HEADING_2) {
      const title = getTextFromHeadingNode(block);

      return [...sections, createSection(title)];
    }

    if (isFirstSection(sections)) {
      return [...sections, createSection('', createDocument([block]))];
    } else {
      addContentToSection(sections, sections.length - 1, block);
      return sections;
    }
  }, []);
}

export function getArticlePresentationTag(article: any): ContentfulSystemTag | undefined {
  const tags: ContentfulSystemTag[] = article?.contentfulMetadata?.tags;

  return tags?.find((tag) =>
    ACCEPTED_ARTICLE_CONTENTFUL_TAGS_LIST.some((acceptedTag) => acceptedTag === tag.id)
  );
}

function getPersonDynamicSlug(card: SectionCard) {
  switch (card.tag.name) {
    case PERSON_CUSTOM_TAG.BOARD_MEMBER:
      return 'link_board_of_directors';
    case PERSON_CUSTOM_TAG.GROUP_EXECUTIVE_MANAGEMENT:
      return 'link_group_executive_management';
    default:
      return 'link_board_of_directors';
  }
}

export function generateEntrySlug(entry: EntrySlugProps, language: URL_LANGUAGE_ENUM): string {
  const dynamicTranslate: TFunction = i18next.getFixedT(language);

  switch (entry.__typename) {
    case ContentfulTypename.ARTICLE: {
      const presentationTag = getArticlePresentationTag(entry);
      const defaultSlug = `/${language}/${dynamicTranslate('link_articles')}/${entry.slug}`;

      if (!presentationTag) return defaultSlug;

      switch (presentationTag.id) {
        case ACCEPTED_ARTICLE_CONTENTFUL_TAGS_ENUM.NEWSARTICLE:
          return `/${language}/${dynamicTranslate('link_news_articles')}/${entry.slug}`;
        default:
          return defaultSlug;
      }
    }

    case ContentfulTypename.ASSEMBLY_LEVEL_0:
      return `/${language}`;

    case ContentfulTypename.ASSEMBLY_LEVEL_1:
      return `/${language}/${entry.slug}`;

    case ContentfulTypename.ASSEMBLY_COLLECTION_PAGE:
      return `/${language}/${dynamicTranslate('link_category')}/${entry.slug}`;

    case ContentfulTypename.NEWSROOM_ASSEMBLY:
      return `/${language}/${dynamicTranslate('link_newsroom')}`;

    case ContentfulTypename.ASSEMBLY_REPORTS_AND_PRESENTATIONS:
      return `/${language}/${dynamicTranslate('link_reports_and_presentations')}?year=${
        entry.year
      }`;

    case ContentfulTypename.PERSON:
      return `/${language}/${dynamicTranslate(getPersonDynamicSlug(entry))}/${entry.slug}`;
    case ContentfulTypename.ASSEMBLY_SOLUTIONS_FLEXIBLE:
      return `/${language}/${dynamicTranslate('link_solutions')}/${dynamicTranslate(
        'link_global'
      )}/${entry.slug}`;

    case BackendArticleType.NEWS:
      if (entry.source === NewsArticleSourceType.CISION) {
        return `/${language}/${dynamicTranslate('link_news')}/${entry.slug}`;
      }
      return `/${language}/${dynamicTranslate('link_news_articles')}/${entry.slug}`;

    case BackendArticleType.PRESS:
      return `/${language}/${dynamicTranslate('link_press_releases')}/${entry.slug}`;

    case BackendArticleType.JOB_AD:
      return `/${language}/${dynamicTranslate('link_careers')}/${dynamicTranslate(
        'link_open_positions'
      )}/${entry.slug}`;

    case BackendArticleType.PRESS_REGULATORY:
      return `/${language}/${dynamicTranslate('link_press_releases')}/${entry.slug}`;

    default:
      return entry.slug;
  }
}

export type ArticleEntryFromRelatedArticlesFromTags =
  RelatedArticlesFromStandaloneTagsFragment['items'][0]['linkedFrom']['relatedArticles']['items'][0];

export function mapAllRelatedArticlesFromTags(
  tags: RelatedArticlesFromStandaloneTagsFragment['items'],
  articleSlug: string,
  language: URL_LANGUAGE_ENUM,
  limit = maxNrOfRelatedArticlesPerSection
): ArticleEntryFromRelatedArticlesFromTags[] {
  const articles: ArticleEntryFromRelatedArticlesFromTags[] = [];

  const isArticleAlreadyAdded = (
    relatedArticle: ArticleEntryFromRelatedArticlesFromTags
  ): boolean => {
    return !!articles.find((article) => article.slug.split('/').pop() === relatedArticle.slug);
  };

  const isArticleDifferentThenParent = (
    relatedArticle: ArticleEntryFromRelatedArticlesFromTags
  ): boolean => {
    return relatedArticle.slug !== articleSlug;
  };

  tags.forEach((tag) => {
    for (const relatedArticle of tag.linkedFrom.relatedArticles.items) {
      if (isArticleDifferentThenParent(relatedArticle) && !isArticleAlreadyAdded(relatedArticle)) {
        const slug = generateEntrySlug(relatedArticle, language);

        articles.push({
          ...relatedArticle,
          slug,
        });
      }
    }
  });

  return articles
    .sort((firstArticle, secondArticle) => {
      const firstPublicationDate = getArticlePublicationDate(
        firstArticle.publicationDate,
        firstArticle.sys.publishedAt
      );
      const secondPublicationDate = getArticlePublicationDate(
        secondArticle.publicationDate,
        secondArticle.sys.publishedAt
      );
      return new Date(secondPublicationDate).getTime() - new Date(firstPublicationDate).getTime();
    })
    .slice(0, limit);
}

export function getArticlePublicationDate(publicationDate: string, publishedAt: string): string {
  return formatDateToUTC(publicationDate || publishedAt, `yyyy-MM-dd HH:mm 'UTC'`);
}

export function generateLazyQueryOptions(article: ArticleFragment | NewsArticleArticleFragment) {
  const tagsFilter = mapAcceptedTagsFilter(article);

  return {
    variables: {
      tagsFilter,
    },
  };
}

export function mapJobPostingCountries(countries: string[]): string {
  return countries.map((country) => updateTextToSentenceCase(country)).join(', ');
}
