import { TeliaIcon } from '@teliads/components/react';
import { Link } from 'react-router-dom';

import { NavigationLink } from 'components/voca/navigation/navigation-stub/models';

interface Props {
  navigationLink: NavigationLink;
  onNavigationChange: () => void;
  slideIn: (navigationLink: NavigationLink) => void;
}

export default function MobileHeaderLevel1Navigation({
  navigationLink,
  onNavigationChange,
  slideIn,
}: Props) {
  return (
    <li className="mobile-header-navigation__list-item">
      {navigationLink.children ? (
        <button
          style={{ color: 'black' }}
          className="expansion-panel__header"
          onClick={() => slideIn(navigationLink)}
        >
          {navigationLink.name}
          <TeliaIcon name="chevron-right" size="sm" />
        </button>
      ) : (
        <Link
          className="mobile-header-navigation__link"
          to={navigationLink.directPath!}
          onClick={onNavigationChange}
        >
          {navigationLink.name}
        </Link>
      )}
    </li>
  );
}
