import { SEOProps } from 'components/custom/SEO/SEO';
import { ContentfulRichText, HeroBannerContent } from 'utils/models';

export enum ContentfulTypename {
  ARTICLE = 'Article',
  ASSEMBLY_LEVEL_0 = 'AssemblyLevel0',
  ASSEMBLY_LEVEL_1 = 'AssemblyLevel1',
  NEWSROOM_ASSEMBLY = 'NewsroomAssembly',
  ASSEMBLY_REPORTS_AND_PRESENTATIONS = 'AssemblyReportsAndPresentations',
  PERSON = 'Person',
  TEASER = 'Teaser',
  ASSEMBLY_COLLECTION_PAGE = 'AssemblyCollectionPage',
  ASSEMBLY_SOLUTIONS_FLEXIBLE = 'AssemblySolutionsFlexible',
}

export type SectionCard = Dictionary<any>;

export enum SectionCardsType {
  STANDARD = 'Standard',
  HIGHLIGHT = 'Highlight',
}

type Section = {
  internalName: string;
  description?: ContentfulRichText;
  contentCollection: {
    items: SectionCard[];
  };
  type: string;
};

export interface CollectionProps {
  heroBanner: HeroBannerContent;
  description?: ContentfulRichText;
  sections: Section[];
  seoMetadata: SEOProps;
}
