import i18n from 'i18next';
import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { contentfulLanguage, currentNavStub, urlLanguage } from 'apollo';
import MobileHeader from 'components/custom/mobile-header/MobileHeader';
import NavigationStub from 'components/voca/navigation/navigation-stub/NavigationStub';
import { TheFooter } from 'components/voca/the-footer';
import TheHeader from 'components/voca/the-header/TheHeader';
import { TranslatedUrlProvider } from 'contexts/translatedUrl.context';
import { URL_LANGUAGE_ENUM, URL_TO_LANGUAGE_CONTENTFUL_MAP } from 'utils/constants';
import useWindowSize from 'utils/hooks/useWindowSize';

import 'components/custom/root-layout/RootLayout.scss';

export default function RootLayout() {
  const MIN_DESKTOP_WIDTH = 1200;
  const location = useLocation();
  const navigate = useNavigate();

  const [windowWidth] = useWindowSize();

  useEffect(() => {
    if (location.pathname.endsWith('/')) {
      navigate(location.pathname.slice(0, -1));
    }

    const lang = location.pathname.split('/')[1] as URL_LANGUAGE_ENUM;
    i18n.changeLanguage(lang);
    urlLanguage(lang);
    contentfulLanguage(URL_TO_LANGUAGE_CONTENTFUL_MAP.get(lang));
    currentNavStub(NavigationStub.get(lang));
  }, [location.pathname, navigate]);

  return (
    <div className="root-layout">
      <TranslatedUrlProvider>
        {windowWidth < MIN_DESKTOP_WIDTH ? <MobileHeader /> : <TheHeader />}

        <main className="root-layout__main">
          <Suspense>
            <Outlet />
          </Suspense>
        </main>

        <TheFooter />
      </TranslatedUrlProvider>
    </div>
  );
}
