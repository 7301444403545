import { ReactNode } from 'react';

interface DisableHyphenationProps {
  children: ReactNode;
}

const DisableHyphenation = ({ children }: DisableHyphenationProps) => {
  return <span style={{ hyphens: 'none' }}>{children}</span>;
};

export default DisableHyphenation;
