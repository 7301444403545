import styles from './InputError.module.scss';

type Props = {
  errorMessage: string;
};

export default function InputError({ errorMessage }: Props) {
  return (
    <p
      data-testid="error-message"
      aria-live="assertive"
      aria-relevant="additions text"
      className={styles['error-message']}
    >
      {errorMessage}
    </p>
  );
}
