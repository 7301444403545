import { HTMLAttributeAnchorTarget } from 'react';

export type Link = {
  title: string;
  url: string;
  type?: 'level2';
};

export enum Variant {
  NAVIGATION = 'navigation',
  LINK = 'link',
  LEVEL_1 = 'level-1',
}

export type NavLink = {
  target?: HTMLAttributeAnchorTarget;
  variant?: Variant;
  language?: string;
  onClick?: () => void;
} & Link;
