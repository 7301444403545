import { TYPES } from 'components/custom/small-card/models';

export enum PERSON_CUSTOM_TAG {
  BOARD_MEMBER = 'Board Member',
  GROUP_EXECUTIVE_MANAGEMENT = 'Group Executive Management',
}

export type ContentfulRichText = any; // TODO: Update with contentful types

interface TableItem {
  title: string;
  category: string[];
  publishedAt: string;
  slug?: string;
  type?: string;
}

export interface TableContent {
  title: string;
  cta?: CtaContent;
  items: TableItem[] | [];
}

export interface CarouselContent<Type> {
  title: string;
  items: Type[];
}

export interface ImageContent {
  url: string;
  altText: string;
  focusArea: string;
}

export interface SmallCardContent {
  title: string;
  category: string;
  buttonText?: string;
  url: string;
  type?: TYPES;
}

export type BaseCardContent = {
  image: ImageContent;
  body: string;
  tagline?: string;
} & SmallCardContent;

export interface CtaContent {
  text: string;
  url: string;
}

interface HeroBanner {
  title: string;
  preamble: ContentfulRichText;
  cta: CtaContent;
}

export interface HeroBannerContent extends HeroBanner {
  image: ImageContent;
  videoUrl: string | undefined;
}

export interface HeroBannerContentOptionalMedia extends HeroBanner {
  image?: ImageContent;
  videoUrl?: string;
}

interface BottomLinkEntry {
  href: string;
  name: string;
}

export interface BillboardContent {
  title: string;
  image: ImageContent;
  preamble: ContentfulRichText;
  cta: CtaContent;
  bottomLinks: BottomLinkEntry[] | [];
}

export interface StatisticsContent {
  title: string;
  body: any; // TODO: Update with contentful type
}

export type NonEmptyArray<T> = [T, ...T[]];

export type CheckboxField = {
  name: string;
  value: string;
  model: string;
  isChecked: boolean;
};
