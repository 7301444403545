import { PropsWithChildren } from 'react';

import styles from './NavItemButton.module.scss';

type Props = {
  variant?: 'primary' | 'secondary';
  onClick: () => void;
  ariaLabel?: string;
  selected?: boolean;
};

export default function NavItemButton({
  variant = 'primary',
  onClick,
  children,
  ariaLabel,
  selected,
}: PropsWithChildren<Props>) {
  const classNames = [
    styles['nav-item-button'],
    styles[`nav-item-button--${variant}`],
    ...(selected ? [styles['nav-item-button--selected']] : []),
  ].join(' ');

  return (
    <button
      data-testid="nav-item-button"
      className={classNames}
      onClick={onClick}
      aria-label={ariaLabel || ''}
      type="button"
    >
      {children}
    </button>
  );
}
