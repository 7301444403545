import {
  ExecutionPatchIncrementalResult,
  ExecutionPatchInitialResult,
  SingleExecutionResult,
} from '@apollo/client';
import { formatInTimeZone } from 'date-fns-tz';

import { GetArticleIdQuery, GetAssemblyCollectionPageIdQuery } from '../graphql/__generated/sdk';

import { URL_LANGUAGE_ENUM } from './constants';

const REGEX_CONTENTFUL_ASSETS = /(images|videos|downloads|assets)\.ctfassets\.net/g;

export const SCROLL_STOP = 'scroll-stop';

export function isMobileView(): boolean {
  const element = document.querySelectorAll('[data-mobile-detector]')[0];
  return !!(element && getComputedStyle(element).display !== 'none');
}

export default function replaceContentfulAssetsHost(
  response: SingleExecutionResult | ExecutionPatchInitialResult | ExecutionPatchIncrementalResult
) {
  return JSON.parse(
    JSON.stringify(response).replaceAll(REGEX_CONTENTFUL_ASSETS, `${process.env.REACT_APP_HOST}/$1`)
  );
}

export function disableScroll() {
  document.body.classList.add(SCROLL_STOP);
}

export function enableScroll() {
  document.body.classList.remove(SCROLL_STOP);
}

export function generateArrayWithUniqueEntries<T>(list: T[]): T[] {
  return Array.from(new Set(list));
}

export function formatDateToUTC(date: string, dateFormat?: string): string {
  return formatInTimeZone(new Date(date), 'UTC', dateFormat || 'yyyy-MM-dd');
}

export function getSlugFromLocationPath(pathname: string): string | undefined {
  return pathname.split('/').pop();
}

export function getEntryId(data: GetArticleIdQuery | GetAssemblyCollectionPageIdQuery | undefined) {
  if (data?.svData?.items.length) {
    return data?.svData?.items[0].sys.id;
  }

  if (data?.enData?.items.length) {
    return data?.enData?.items[0].sys.id;
  }
}

function getAspectRatioWidth(aspectRatio: `${string}:${string}`, height: number): number {
  const [widthAspect, heightAspect] = aspectRatio.split(':');
  return Math.ceil((height * Number(widthAspect)) / Number(heightAspect));
}

// Tuple values: [Aspect-Ratio, Height]
export type Dimensions = {
  small: [`${string}:${string}`, number];
  medium: [`${string}:${string}`, number];
  large: [`${string}:${string}`, number];
};

const defaultDimensions: Dimensions = {
  small: ['4:3', 450],
  medium: ['16:9', 650],
  large: ['16:9', 1125],
};

export function createMultipleSizeImages(
  {
    url,
    altText = '',
    focusArea,
  }: {
    url: string;
    altText?: string;
    focusArea?: string;
  },
  dimensions = defaultDimensions
) {
  const sizesInPixels = {
    small: [getAspectRatioWidth(...dimensions.small), dimensions.small[1]],
    medium: [getAspectRatioWidth(...dimensions.medium), dimensions.medium[1]],
    large: [getAspectRatioWidth(...dimensions.large), dimensions.large[1]],
  };
  const urlWithFocusArea = createUrlWithFocusArea(url, focusArea);

  return {
    default: urlWithFocusArea,
    small: `${urlWithFocusArea}&w=${sizesInPixels.small[0]}&h=${sizesInPixels.small[1]}`,
    medium: `${urlWithFocusArea}&w=${sizesInPixels.medium[0]}&h=${sizesInPixels.medium[1]}`,
    large: `${urlWithFocusArea}&w=${sizesInPixels.large[0]}&h=${sizesInPixels.large[1]}`,
    altText,
  };
}

export function createUrlWithFocusArea(url: string, focusArea?: string) {
  const FOCUS = focusArea || 'center';
  const RESIZE_STRATEGY = 'fill';
  const isUrlWithFocusArea = url.split('&f=').length === 2;

  if (isUrlWithFocusArea) {
    return url;
  } else {
    return `${url}&f=${FOCUS}&fit=${RESIZE_STRATEGY}`;
  }
}

export function parseNumericString(val: any) {
  return isNaN(val) ? val : parseInt(val);
}

export function updateTextToSentenceCase(val: string) {
  return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
}

export function clearSpacesAndFirstSlash(input: string) {
  const removedSpacesAndFirstSlash = input.replace(/\s+/g, '').replace('/', '');
  return removedSpacesAndFirstSlash.toLocaleLowerCase();
}

export function generateStringsDictionary(values: string) {
  const valuesArray = values.split(',');

  const obj: StringsDictionary = {};

  valuesArray.forEach((value) => {
    const key = clearSpacesAndFirstSlash(value);
    obj[key] = value;
  });

  return obj;
}

export function getLinkTarget(link: string | undefined): '_blank' | '_self' | undefined {
  if (!link) return;

  const hostname = 'teliacompany.com';
  const isLocalLink =
    link.startsWith(`https://${hostname}`) ||
    link.startsWith(`http://${hostname}`) ||
    link.startsWith(`https://www.${hostname}`) ||
    link.startsWith(`http://www.${hostname}`) ||
    link.startsWith(`www.${hostname}`) ||
    link.startsWith(hostname) ||
    link.startsWith(`/${URL_LANGUAGE_ENUM.ENGLISH}`) ||
    link.startsWith(`/${URL_LANGUAGE_ENUM.SWEDISH}`);

  if (isLocalLink) {
    return '_self';
  }

  return '_blank';
}

export const TruncatedTextByWords = (title: string, wordCount = 4) => {
  const words = title.split(' ');
  const separator = '...';

  if (words.length <= wordCount) {
    return title;
  }

  const truncatedText = words.slice(0, wordCount).join(' ') + separator;

  return truncatedText;
};

export const getDatablockInfo = (url: string) => {
  const urlObject = new URL(url);
  const widget = urlObject.pathname.split('/').pop();
  const token = urlObject.searchParams.get('token') || undefined;

  return { widget, token };
};
