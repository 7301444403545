import { t } from 'i18next';

import { NavigationLink } from '../navigation/navigation-stub/models';
import NavigationStub from '../navigation/navigation-stub/NavigationStub';

import { urlLanguage } from 'apollo';
import {
  ArticleCardFragment,
  GetMenuTeasersQuery,
  TeaserCardFragment,
} from 'graphql/__generated/sdk';
import { URL_LANGUAGE_ENUM } from 'utils/constants';
import { generateEntrySlug } from 'utils/mappers';
import { BaseCardContent } from 'utils/models';
import { renderPlainTextFromRichText } from 'utils/rich-text-render';
import { generateBaseCard, getFirstContentItem } from 'utils/transformers';

export type mappedTeaserData = {
  section: string;
  content: BaseCardContent;
}[];

function buildNavigationLinks(nav: NavigationLink) {
  if (isDropdown(nav)) {
    return buildDropdownButton(nav);
  } else {
    return buildDirectLink(nav);
  }
}

function buildDropdownButton(nav: NavigationLink) {
  const sectionsWithoutFirstSection = nav.children ? nav.children.slice(1) : [];

  return {
    children: sectionsWithoutFirstSection.map((navChild) => buildSection(navChild, nav)),
    url: nav.directPath,
    name: nav.name,
  };
}

function buildDirectLink(nav: NavigationLink, parentNav = {} as NavigationLink) {
  return {
    url: nav.directPath || parentNav.directPath,
    name: nav.name,
  };
}

function buildSection(nav: NavigationLink, parentNav: NavigationLink) {
  return {
    heading: nav.name,
    links: buildSectionNavLinks(nav),
    sectionLink: {
      title: nav.name,
      url: nav.directPath || parentNav.directPath,
    },
  };
}

function buildSectionNavLinks(nav: NavigationLink) {
  const linksWithoutSectionLink = nav.children ? nav.children.slice(1) : [];

  return linksWithoutSectionLink.map((navChild) => ({
    title: navChild.name,
    url: navChild.directPath || nav.directPath,
    type: navChild.type,
  }));
}

function isDropdown(nav: NavigationLink) {
  return nav.children && nav.children?.length > 0;
}

export function generateLinksWithSections(urlLanguage: URL_LANGUAGE_ENUM) {
  const navigationLinks = NavigationStub.get(urlLanguage)!.getLinks();

  return navigationLinks.map((nav: NavigationLink) => buildNavigationLinks(nav));
}

export function buildTeaserCards(
  card: ArticleCardFragment | TeaserCardFragment,
  language: URL_LANGUAGE_ENUM
): BaseCardContent {
  if (card.__typename === 'Article') {
    const url = generateEntrySlug(card, language);

    return generateBaseCard({
      title: card.title,
      url,
      image: getFirstContentItem(card.images),
      body: card.preamble || '',
    });
  }
  return generateBaseCard({
    title: card.title,
    url: card.teaserUrl || '',
    image: card?.primaryMediaImage,
    body: renderPlainTextFromRichText(card.richPreamble?.json) || '',
    tagline: card.tagline || '',
  });
}

export function mapTeaserData(data: GetMenuTeasersQuery | undefined) {
  const result = [];
  if (data && data.data.items.length) {
    const content = data.data.items[0];
    for (const [key, value] of Object.entries(content)) {
      if (key !== '__typename' && value && typeof value !== 'string') {
        const teaserItem = {
          section: getTranslation(key),
          content: buildTeaserCards(value, urlLanguage()),
        };
        result.push(teaserItem);
      }
    }
  }
  return result;
}

const getTranslation = (text: string) => {
  switch (text) {
    case 'aboutTheCompany':
      return t('navigation.about_the_company.title');
    case 'solutions':
      return t('navigation.solutions.title');
    case 'newsroom':
      return t('navigation.newsroom.title');
    case 'investors':
      return t('navigation.investors.title');
    case 'sustainability':
      return t('navigation.sustainability.title');
    case 'careers':
      return t('navigation.careers.title');
    case 'contactUs':
      return t('navigation.contact_us.title');
    default:
      return 'null';
  }
};
