export enum URL_LANGUAGE_ENUM {
  ENGLISH = 'en',
  SWEDISH = 'sv',
}

export enum CONTENTFUL_LANGUAGE_ENUM {
  ENGLISH = 'en-US',
  SWEDISH = 'sv',
}

export const URL_TO_LANGUAGE_CONTENTFUL_MAP = new Map<URL_LANGUAGE_ENUM, CONTENTFUL_LANGUAGE_ENUM>([
  [URL_LANGUAGE_ENUM.ENGLISH, CONTENTFUL_LANGUAGE_ENUM.ENGLISH],
  [URL_LANGUAGE_ENUM.SWEDISH, CONTENTFUL_LANGUAGE_ENUM.SWEDISH],
]);

export const ALL_TOPICS = 'All topics';

export const animationDurationMs = 0.25 * 1000;
export const transitionTimingFunction = 'cubic-bezier(0.4, 0, 0.2, 1)';

export const INTERACTIVE_ELEMENTS =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), telia-navigation-button';

export enum ACCEPTED_ARTICLE_NEWS_TAGS_ENUM {
  AboutTheCompany = 'about-the-company',
  ProductsAndServices = 'products-and-services',
  Career = 'career',
  Sustainability = 'topic-sustainability',
  FinancialInformation = 'financial-information',
}

export const ACCEPTED_ARTICLE_NEWS_TAGS_LIST: (ACCEPTED_ARTICLE_NEWS_TAGS_ENUM | string)[] =
  Object.values(ACCEPTED_ARTICLE_NEWS_TAGS_ENUM);

export enum ACCEPTED_ARTICLE_CONTENTFUL_TAGS_ENUM {
  NEWSARTICLE = 'newsArticle',
}

export const ACCEPTED_ARTICLE_CONTENTFUL_TAGS_LIST: (
  | ACCEPTED_ARTICLE_CONTENTFUL_TAGS_ENUM
  | string
)[] = Object.values(ACCEPTED_ARTICLE_CONTENTFUL_TAGS_ENUM);

export const FALLBACK_BLANK_IMAGE_URL = '/image/dark_blank.png';
export const maxNrOfRelatedArticlesPerSection = 7;
export const SEARCH_QUERY_REGEX =
  '^\\s*[a-zA-Z0-9ÆØÅæøåÄÖÜäöüĄČĘĖĮŠŲŪŽąčęėįšųūž,?!;-]+(?:\\s+[a-zA-Z0-9ÆØÅæøåÄÖÜäöüĄČĘĖĮŠŲŪŽąčęėįšųūž,?!;-]+)*\\s*$';

export enum CONTENTFUL_ASSET_TYPE {
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PRESENTATION = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum KEYBOARD_KEY {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
}

export enum RESULT_CATEGORY_VALUE {
  SUSTAINABILITY = 'Newsroom_filters.sustainability',
  ABOUT_COMPANY = 'Newsroom_filters.about_company',
  PRODUCTS_SERVICES = 'Newsroom_filters.product_services',
  CAREER = 'Newsroom_filters.career',
  FINANCIAL = 'Newsroom_filters.financial',
}
