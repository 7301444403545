import { useRef } from 'react';

import { getSlugFromLocationPath } from '../../../utils/utils';

export default function IframeEuroland({ url, title }: StringsDictionary) {
  const iframeContainer = useRef<HTMLDivElement>(null);
  const slug = getSlugFromLocationPath(window.location.pathname);
  function handleIframeLoad() {
    // @ts-ignore
    EurolandToolIntegrationObject.set(slug);
  }

  return (
    <div ref={iframeContainer}>
      <iframe
        onLoad={handleIframeLoad}
        src={url}
        allowFullScreen
        height="500"
        width="100%"
        id={slug}
        frameBorder="0"
        title={title}
        loading="lazy"
      />
    </div>
  );
}
