import { TeliaHeading, TeliaIcon } from '@teliads/components/react';
import { useTranslation } from 'react-i18next';

import styles from './NavTeaser.module.scss';

import { createMultipleSizeImages } from 'utils/utils';

type Props = {
  teaserHeading?: string;
  image: {
    url: string;
    description: string;
  };
  teaserTitle: string;
  cta: {
    ariaLabel: string;
    title: string;
    url: string;
  };
};

export default function NavTeaser({ teaserHeading, image, teaserTitle, cta }: Props) {
  const { t } = useTranslation();

  const imageSizes = createMultipleSizeImages({
    url: image.url,
    altText: image.description,
  });

  return (
    <section
      className={styles['nav-teaser__content-wrapper']}
      key={teaserHeading}
      data-testid="nav-teaser-title"
    >
      <div className={styles['nav-teaser__content']}>
        <TeliaHeading variant="title-100" tag="h2">
          {teaserHeading || t('related_content')}
        </TeliaHeading>

        <img
          className={styles['nav-teaser__content--image']}
          src={imageSizes.small}
          alt={imageSizes.altText}
        />

        <span className={styles['nav-teaser__content--heading']}>{teaserTitle}</span>

        <a aria-label={teaserTitle} href={cta.url} className={styles['nav-teaser__content--cta']}>
          {cta.title || t('read_more')}
          <TeliaIcon name="arrow-right" size="sm" />
        </a>
      </div>
    </section>
  );
}
