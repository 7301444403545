import i18n from 'i18next';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDetectScrollDown } from './useDetectScrollDown';

import { urlLanguage } from 'apollo';
import Logo from 'components/custom/logo/Logo';
import { TeliaHeader } from 'components/custom/Navigation';
import { generateLinksWithSections, mapTeaserData } from 'components/voca/the-header/transformers';
import { useTranslatedUrlContext } from 'contexts/translatedUrl.context';
import { GetMenuTeasersDocument, GetMenuTeasersQuery } from 'graphql/__generated/sdk';
import { URL_LANGUAGE_ENUM } from 'utils/constants';
import useI18nQuery from 'utils/hooks/useI18nQuery';
import useNavigationSearch from 'utils/hooks/useNavigationSearch';
import 'components/voca/the-header/TheHeader.scss';

interface languageBarHeightVariable extends React.CSSProperties {
  '--languageBarHeight'?: string;
}

export default function TheHeader() {
  const { t } = useTranslation();
  const { navigateToTranslatedUrl } = useTranslatedUrlContext();
  const refference = useRef<HTMLDivElement>(null);

  const { suggestions, setSearchQuery, onSearch } = useNavigationSearch();

  const isScrollingDown = useDetectScrollDown();

  const languageBarHeight = refference.current?.children[0].children[0].clientHeight;

  const languageBarHeightVariable: languageBarHeightVariable = {
    '--languageBarHeight': `${languageBarHeight ? languageBarHeight * -1 : 48}px`,
  };

  const { data } = useI18nQuery<GetMenuTeasersQuery>(GetMenuTeasersDocument);
  const mappedData = mapTeaserData(data);

  return (
    <div
      className={`the-header ${isScrollingDown ? 'hidden' : ''}`}
      style={languageBarHeightVariable}
      ref={refference}
    >
      <TeliaHeader
        links={generateLinksWithSections(urlLanguage())}
        navTeasers={mappedData}
        i18n={i18n}
        onSearch={onSearch}
        isSticky
        selectedLanguage={urlLanguage()}
        availableLanguages={Object.values(URL_LANGUAGE_ENUM)}
        onLanguageChange={(lang) => navigateToTranslatedUrl(lang as URL_LANGUAGE_ENUM)}
        logo={<Logo />}
        handleSearchInput={setSearchQuery}
        searchSuggestions={suggestions}
        searchPlaceholder={`${t('actions.search')}...`}
      />
    </div>
  );
}
