import { TeliaList, TeliaNavigationButton } from '@teliads/components/react';

import { urlLanguage } from 'apollo';
import { useTranslatedUrlContext } from 'contexts/translatedUrl.context';
import { URL_LANGUAGE_ENUM } from 'utils/constants';

import 'components/voca/navigation/NavigationDesktopLanguageToggle/NavigationDesktopLanguageToggle.scss';

export default function NavigationDesktopLanguageToggle() {
  const { navigateToTranslatedUrl } = useTranslatedUrlContext();

  return (
    <TeliaList item-spacing="true" variant="row" slot="language">
      {Object.values(URL_LANGUAGE_ENUM).map((lang) => (
        <li key={lang}>
          <TeliaNavigationButton
            size="sm"
            active={lang === urlLanguage()}
            onClick={() => navigateToTranslatedUrl(lang)}
            data-testid="country-code"
          >
            <span className="navigation-desktop-language__lang">{lang}</span>
          </TeliaNavigationButton>
        </li>
      ))}
    </TeliaList>
  );
}
