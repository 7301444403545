import { ParagraphVariantType } from '@teliads/components/dist/types/types/ParagraphVariant';
import { TeliaP } from '@teliads/components/react';
import { ReactNode } from 'react';

type ParagraphProps = {
  variant?: ParagraphVariantType;
  className?: string;
  children: ReactNode;
  dataTestId?: string;
};

const Paragraph = ({
  variant = 'paragraph-100',
  className,
  children,
  dataTestId,
}: ParagraphProps) => {
  return (
    <TeliaP variant={variant} className={className} data-testid={dataTestId} disableHyphenation>
      {children}
    </TeliaP>
  );
};

export default Paragraph;
