import { Icon, iconList } from '@purpurds/purpur';

interface Props {
  iconName: keyof typeof iconList;
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const PurpurIcon = ({ iconName, size = 'md' }: Props) => {
  return <Icon svg={iconList[iconName]} size={size} />;
};

export default PurpurIcon;
