import { TeliaGrid } from '@teliads/components/react';

import NavItemButton from '../NavItemButton/NavItemButton';

import styles from './LanguageSwitcher.module.scss';

type Props = {
  selectedLanguage: string;
  availableLanguages: string[];
  onLanguageChange: (lang: string) => void;
};

export default function LanguageSwitcher({
  selectedLanguage,
  availableLanguages,
  onLanguageChange,
}: Props) {
  return (
    <div className={styles['language-switcher-wrapper']}>
      <TeliaGrid>
        <ul className={styles['language-switcher']} data-testid="language-switcher">
          {availableLanguages.map((lang) => (
            <li
              key={lang}
              className={styles['language-switcher__language']}
              data-testid="language-item"
            >
              <NavItemButton
                variant="secondary"
                selected={lang.toLowerCase() === selectedLanguage.toLowerCase()}
                onClick={() => onLanguageChange(lang)}
              >
                {lang.toUpperCase()}
              </NavItemButton>
            </li>
          ))}
        </ul>
      </TeliaGrid>
    </div>
  );
}
