import { TeliaIcon } from '@teliads/components/react';
import { search } from '@teliads/icons';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import NavItemButton from '../NavItemButton';
import SearchInput from '../SearchInput/SearchInput';

import styles from './HeaderSearchInput.module.scss';

type Props = {
  selectedLanguage: string;
  searchSuggestions: string[];
  isAllResultsLinkHidden: boolean;
  onSearch: () => void;
  handleSearchInput?: (value: SetStateAction<string>) => void;
  placeholder?: string;
};

export default function HeaderSearchInput({
  selectedLanguage,
  handleSearchInput,
  onSearch,
  searchSuggestions,
  isAllResultsLinkHidden,
  placeholder,
}: Props) {
  const { t } = useTranslation();

  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchInputWrapperRef = useRef<HTMLDivElement>(null);
  const searchButtonWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function closeSearchInput({ key }: KeyboardEvent) {
      if (key === 'Escape') {
        setIsSearchInputOpen(false);
      }
    }

    document.addEventListener('keydown', closeSearchInput);
    return () => document.removeEventListener('keydown', closeSearchInput);
  }, []);

  return (
    <div className={`${styles['header-search-input']} ${styles['header-search-input--search']}`}>
      <CSSTransition
        nodeRef={searchInputWrapperRef}
        in={isSearchInputOpen}
        timeout={200}
        classNames={{
          enter: styles['header-search-input--enter'],
          enterActive: styles['header-search-input--enter-active'],
          enterDone: styles['header-search-input--enter-done'],
          exit: styles['header-search-input--exit'],
          exitActive: styles['header-search-input--exit-active'],
        }}
        mountOnEnter
        unmountOnExit
      >
        <div ref={searchInputWrapperRef}>
          <SearchInput
            value={searchText}
            updateValue={(value) => {
              setSearchText(value);
              handleSearchInput!(value);
            }}
            onSearch={onSearch}
            language={selectedLanguage}
            searchSuggestions={searchSuggestions}
            isAllResultsLinkHidden={isAllResultsLinkHidden}
            handleBlur={() => setIsSearchInputOpen(false)}
            placeholder={placeholder}
          />
        </div>
      </CSSTransition>
      <CSSTransition
        nodeRef={searchButtonWrapperRef}
        in={!isSearchInputOpen}
        timeout={200}
        classNames={{
          enterActive: styles['search-button--enter-active'],
          exit: styles['search-button--exit'],
          enterDone: styles['search-button--enter-done'],
        }}
        mountOnEnter
        unmountOnExit
      >
        <div ref={searchButtonWrapperRef}>
          <NavItemButton onClick={() => setIsSearchInputOpen(true)}>
            <TeliaIcon
              size="md"
              svg={search.svg}
              ally-title={t('telia-navigation:icon.magnifying_glass', {
                lng: selectedLanguage,
              })}
            />
            <span className={styles['header-search-input__search-text']}>
              {t('telia-navigation:search', { lng: selectedLanguage })}
            </span>
          </NavItemButton>
        </div>
      </CSSTransition>
    </div>
  );
}
