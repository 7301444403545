import type { Location } from '@remix-run/router';
import i18next from 'i18next';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { contentfulLanguage, currentNavStub, urlLanguage } from 'apollo';
import NavigationStub from 'components/voca/navigation/navigation-stub/NavigationStub';
import { getKeyByValue } from 'i18n';
import { URL_LANGUAGE_ENUM, URL_TO_LANGUAGE_CONTENTFUL_MAP } from 'utils/constants';
import { slugLocaleMap } from 'utils/translation.utils';
import { getSlugFromLocationPath } from 'utils/utils';

const defaultLanguage = {
  enSlug: '',
  svSlug: '',
};

export type AcceptableSlugData = {
  enSlug: string;
  svSlug: string;
};

type ContextType = {
  slugs: AcceptableSlugData | null;
  setSlugs: Dispatch<SetStateAction<AcceptableSlugData>>;
  navigateToTranslatedUrl: (lang: URL_LANGUAGE_ENUM) => void;
};

const arrayToUrlPath = (pathArray: string[]) => `/${pathArray.join('/')}`;

const isMainIndexPage = (location: Location): boolean => {
  return location.pathname.split('/').length === 2;
};

export const TranslatedUrl = createContext<ContextType | null>(null);

export const TranslatedUrlProvider = ({ children }: PropsWithChildren) => {
  const [slugs, setSlugs] = useState(defaultLanguage);

  const currentNav = currentNavStub();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const slug = getSlugFromLocationPath(location.pathname)!;

  const getTranslatedPath = (newLanguage: URL_LANGUAGE_ENUM, translatedSlug: string): string => {
    const splitPathname = location.pathname.split('/');
    const oldLanguage = splitPathname[1];
    const { translation } = i18next.getDataByLanguage(oldLanguage)!;

    const urlPathParts = splitPathname.slice(2, splitPathname.length - 1);

    if (urlPathParts.length) {
      const translatedPrefix = urlPathParts
        .map((prefix) => {
          const dynamicKey = getKeyByValue(translation, prefix)!;
          return t(dynamicKey);
        })
        .join('/');

      return arrayToUrlPath([newLanguage, translatedPrefix, translatedSlug]);
    }

    return arrayToUrlPath([newLanguage, translatedSlug]);
  };

  const extractSearchParams = (slugs: StringsDictionary, lang: URL_LANGUAGE_ENUM) => {
    const slugWithSearchParams = slugs[slugLocaleMap.get(lang)!];

    if (slugWithSearchParams && slugWithSearchParams.includes('?')) {
      return slugWithSearchParams.split('?')[1];
    }

    return '';
  };

  const navigateToTranslatedUrl = async (lang: URL_LANGUAGE_ENUM) => {
    await i18next.changeLanguage(lang);
    urlLanguage(lang);
    contentfulLanguage(URL_TO_LANGUAGE_CONTENTFUL_MAP.get(lang)!);

    if (isMainIndexPage(location)) {
      return navigate(`/${lang}`);
    }

    const hardcodedRoute = currentNav.findLinkBy('slug', slug)!;

    if (hardcodedRoute) {
      const translatedRoute = NavigationStub.get(lang)!.findLinkBy('id', hardcodedRoute.id)!;
      const searchParams = slugs ? extractSearchParams(slugs, lang) : '';

      return navigate(translatedRoute.directPath + (searchParams ? `?${searchParams}` : ''));
    }

    if (slugs) {
      return navigate(getTranslatedPath(lang, slugs[slugLocaleMap.get(lang)!]));
    }

    return navigate(`/${lang}`);
  };

  return (
    <TranslatedUrl.Provider value={{ slugs, setSlugs, navigateToTranslatedUrl }}>
      {children}
    </TranslatedUrl.Provider>
  );
};

export const useTranslatedUrlContext = () => {
  return useContext(TranslatedUrl) as ContextType;
};
