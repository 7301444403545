import { Variant } from '../../types/common';
import { Section as SectionProps } from '../MegaMenu/types';
import NavLink from '../NavLink/NavLink';

import styles from './Section.module.scss';

export default function Section({ heading, links, sectionLink, handleNavigation }: SectionProps) {
  return (
    <div className={styles.section}>
      <ul className={styles.section__list} key={heading}>
        {sectionLink && sectionLink.title && (
          <li>
            <NavLink
              url={sectionLink.url}
              title={sectionLink.title}
              variant={Variant.LINK}
              onClick={handleNavigation}
            />
          </li>
        )}
        {links.map(({ title, url, type }) => (
          <li key={title}>
            {type ? (
              <NavLink url={url} title={title} variant={Variant.LINK} onClick={handleNavigation} />
            ) : (
              <NavLink url={url} title={title} onClick={handleNavigation} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
