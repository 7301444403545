import { useEffect } from 'react';

import { KEYBOARD_KEY } from 'utils/constants';

export default function useEventOnEscapeKey(callback: () => void) {
  useEffect(() => {
    function callCallbackOnEscapeKey(e: KeyboardEvent) {
      if (e.key === KEYBOARD_KEY.ESCAPE) {
        callback();
      }
    }

    document.addEventListener('keydown', callCallbackOnEscapeKey);

    return () => {
      document.removeEventListener('keydown', callCallbackOnEscapeKey);
    };
  }, [callback]);
}
