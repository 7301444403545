import { RefObject, useCallback, useEffect } from 'react';

import { isMobileView } from 'utils/utils';

export default function useHorizontalScrollGradient(
  ref: RefObject<HTMLElement>,
  coverSize: 'sm' | 'md' = 'sm',
  viewport: 'mobile' | 'desktop' | 'all' = 'all'
) {
  const gradientLeftClassname = `horizontal-scroll--gradient-left-${coverSize}`;
  const gradientRightClassname = `horizontal-scroll--gradient-right-${coverSize}`;

  const getGradientPosition = useCallback(() => {
    const ERROR_MARGIN = 5;

    if (!ref.current) return;

    const isScrollAtStart = Math.abs(ref.current.scrollLeft) <= ERROR_MARGIN;
    const isScrollAtEnd =
      Math.abs(ref.current.scrollWidth - ref.current.scrollLeft - ref.current.clientWidth) <=
      ERROR_MARGIN;

    if (isScrollAtStart) {
      ref.current.classList.remove(gradientLeftClassname);
      ref.current.classList.add(gradientRightClassname);
    } else if (isScrollAtEnd) {
      ref.current.classList.remove(gradientRightClassname);
      ref.current.classList.add(gradientLeftClassname);
    }
  }, [ref, gradientLeftClassname, gradientRightClassname]);

  const checkIfElementIsScrollable = useCallback(() => {
    const el = ref.current;

    if (!el) return;

    if ((viewport === 'mobile' && !isMobileView()) || (viewport === 'desktop' && isMobileView())) {
      el.classList?.remove(gradientLeftClassname);
      el.classList?.remove(gradientRightClassname);

      return;
    }

    if (el.scrollWidth > el.clientWidth) {
      getGradientPosition();
    } else {
      el.classList?.remove(gradientLeftClassname);
      el.classList?.remove(gradientRightClassname);
    }
  }, [getGradientPosition, ref, viewport, gradientLeftClassname, gradientRightClassname]);

  useEffect(() => {
    const refCurrent = ref.current;

    const handleScroll = () => {
      checkIfElementIsScrollable();
    };

    if (refCurrent) {
      refCurrent.addEventListener('scroll', handleScroll);

      return () => {
        refCurrent.removeEventListener('scroll', handleScroll);
      };
    }
  }, [ref, getGradientPosition, checkIfElementIsScrollable]);

  useEffect(() => {
    checkIfElementIsScrollable();

    window.addEventListener('resize', checkIfElementIsScrollable);
    return () => window.removeEventListener('resize', checkIfElementIsScrollable);
  }, [
    ref,
    viewport,
    getGradientPosition,
    gradientLeftClassname,
    gradientRightClassname,
    checkIfElementIsScrollable,
  ]);
}
