import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useGetSearchSuggestions from 'utils/hooks/useGetSearchSuggestions';

export default function useNavigationSearch() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const suggestions = useGetSearchSuggestions(searchQuery, i18n.language);

  const onSearch = () => {
    setSearchQuery('');
    navigate(`/${i18n.language}/${t('link_search_results')}?query=${searchQuery}`);
  };

  return { searchQuery, setSearchQuery, suggestions, onSearch };
}
